import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, NavLink } from 'react-router-dom'
import LogInOut from '../LogInOut';
import { connect } from "react-redux";

let expand = 'md'

function NavbarD({ user }) {
  return (<>
    <Navbar expand="md" className="bg-body-tertiary" bg="light" data-bs-theme="light">
      <Container>
        <Navbar.Brand as={NavLink} to="/">BerserkMeta</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/">Главная</Nav.Link>
            <Nav.Link as={NavLink} to="/sets/">Выпуски</Nav.Link>
            {
              //<Nav.Link as={NavLink} to="/decks/">Колоды</Nav.Link>
            }
            <Nav.Link as={NavLink} to="/tournaments/">Турниры</Nav.Link>
            {
              //<Nav.Link as={NavLink} to="/news/">Новости</Nav.Link>
            }

            <NavDropdown title="Статистика" id="collasible-nav-dropdown">
              <NavDropdown.Item as={NavLink} to="/statistics/clubs/">Клубы</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/statistics/decks/">Колоды</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/statistics/cards/">Карты</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={NavLink} to="/statistics/website/">Сайт</NavDropdown.Item>
            </NavDropdown>

          </Nav>
          {
            user ? <>Привет,&nbsp;<Nav.Link as={NavLink} to="/myprofile/"> { user.first_name}</Nav.Link>  !</>: ''
          }
          <Nav>
            <LogInOut />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>)
}

//export default  NavbarD;


const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, null)(NavbarD);
/*

,
    user: state.auth.user
    Привет, {user.first_name}

*/