import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import config_site from '../../config_site';

import {
    NavLink,
} from 'react-router-dom';
import { Breadcrumb, Col, Container, Row, Table } from 'react-bootstrap';

function AllMonthStatistics(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    let month = {
        1: 'Январь',
        2: 'Февраль',
        3: 'Март',
        4: 'Апрель',
        5: 'Май',
        6: 'Июнь',
        7: 'Июль',
        8: 'Август',
        9: 'Сентябрь',
        10: 'Октябрь',
        11: 'Ноябрь',
        12: 'Январь',
    }

    useEffect(() => {
        fetch(`${config_site.apiBaseUrl}/api/MonthStatistics/`)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);
            });
        return () => {
            setData([]);
            setIsLoading(true);
        };
    }, []);

    return isLoading || (data === []) ? (
        <div>Loading...</div>
    ) : (
        <Container className="mt-3">

            
<Breadcrumb className='mt-3'>
            <Breadcrumb.Item><NavLink to={'/'}>Главная</NavLink></Breadcrumb.Item>
            <Breadcrumb.Item><NavLink to={'/statistics/cards/'}>Статистика по картам</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item active>По месяцам</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                {
                    data.map((item, id) => (
                        <Col md={6}>
                            <Card key={id} className="mb-3">
                                <Row className="align-middle">
                                    <Col><h4><Link to={"/statistics/Month/" + item.year + "/" + item.month}>{month[item.month]}<br />{item.year}</Link></h4></Col>
                                    {item.best_rarity_0 ? <><img key={id} style={{ width: '8.0rem' }} src={`${config_site.apiBaseUrl.replace('8000', '3000')}/src/img/` + item.best_rarity_0} /></> : <></>}
                                    {item.best_rarity_1 ? <><img key={id} style={{ width: '8.0rem' }} src={`${config_site.apiBaseUrl.replace('8000', '3000')}/src/img/` + item.best_rarity_1} /></> : <></>}
                                    {item.best_rarity_2 ? <><img key={id} style={{ width: '8.0rem' }} src={`${config_site.apiBaseUrl.replace('8000', '3000')}/src/img/` + item.best_rarity_2} /></> : <></>}
                                    {item.best_rarity_3 ? <><img key={id} style={{ width: '8.0rem' }} src={`${config_site.apiBaseUrl.replace('8000', '3000')}/src/img/` + item.best_rarity_3} /></> : <></>}



                                </Row>
                            </Card>
                        </Col>
                    ))}
            </Row>
        </Container>
    );
}

export default AllMonthStatistics;

/*
{item.best_cards.map((card, id) => (
                                        <Col>
                                            <img key={id} style={{ width: '8.8rem' }} src={`${config_site.apiBaseUrl}/src/img/` + card.card_img} /></Col>

                                    ))}
                                            */