import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    GOOGLE_AUTH_SUCCESS,
    GOOGLE_AUTH_FAIL,
    FACEBOOK_AUTH_SUCCESS,
    FACEBOOK_AUTH_FAIL,
    LOGOUT,
    REFRESH_SUCCESS
} from '../actions/types';

import jwt_decode from "jwt-decode";


const loadIsAuthenticated = () => {
    let rez = localStorage.getItem('access')
    ////console.log('rez')
    if (rez !== null) {
        ////console.log(rez)
        //  var decoded = jwt_decode(rez);
        ////console.log(decoded['user_id'])

        ////console.log('localStorage.getItem("access")')
        ////console.log(localStorage.getItem('access'))
        return true
    }

    return false
}

const loadUserItemOnJWT = () => {
    let rez = localStorage.getItem('access')
    //console.log('rez')
    if (rez !== null) {
        //console.log(rez)
        var decoded = jwt_decode(rez);
        //console.log('user_id')
        //console.log(decoded['user_id'])
        return decoded['user_id']
    }

    return null
}

const loadis_club_ownerOnJWT = () => {
    let rez = localStorage.getItem('access')
    //console.log('rez')
    if (rez !== null) {
        //console.log(rez)
        var decoded = jwt_decode(rez);
        console.log("decoded:")
        console.log(decoded)
        //console.log('is_club_owner')
        //console.log(decoded['is_club_owner'])
        return decoded['is_club_owner']
    }

    return false
}

const loadis_newsmakerOnJWT = () => {
    let rez = localStorage.getItem('access')
    //console.log('rez')
    if (rez !== null) {
        //console.log(rez)
        var decoded = jwt_decode(rez);
        //console.log('is_newsmaker')
        //console.log(decoded['is_newsmaker'])
        return decoded['is_newsmaker']
    }

    return false
}

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: loadIsAuthenticated(),
    user: null,
    userId: loadUserItemOnJWT(),
    is_club_owner: loadis_club_ownerOnJWT(),
    is_newsmaker: loadis_newsmakerOnJWT(),
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true
            }
        case LOGIN_SUCCESS:
        case GOOGLE_AUTH_SUCCESS:
        case FACEBOOK_AUTH_SUCCESS:
            localStorage.setItem('access', payload.access);
            localStorage.setItem('refresh', payload.refresh);
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh,
                userId: loadUserItemOnJWT(),
                is_club_owner: loadis_club_ownerOnJWT(),
                is_newsmaker: loadis_newsmakerOnJWT(),
            }
        case SIGNUP_SUCCESS:
            return {
                ...state,
                isAuthenticated: false
            }
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload
            }
        case AUTHENTICATED_FAIL:
            console.log("AUTHENTICATED_FAIL")
            return {
                ...state,
                isAuthenticated: false
            }
        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null
            }
        case REFRESH_SUCCESS:
            localStorage.setItem('access', payload.access);
            return {
                ...state,
                //user: null
            }
        case GOOGLE_AUTH_FAIL:
        case FACEBOOK_AUTH_FAIL:
        case LOGIN_FAIL:
        case SIGNUP_FAIL:
        case LOGOUT:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null,
                is_club_owner: null,
                is_newsmaker: null,
            }
        case PASSWORD_RESET_SUCCESS:
        case PASSWORD_RESET_FAIL:
        case PASSWORD_RESET_CONFIRM_SUCCESS:
        case PASSWORD_RESET_CONFIRM_FAIL:
        case ACTIVATION_SUCCESS:
        case ACTIVATION_FAIL:
            return {
                ...state
            }
        default:
            return state
    }
};
