import axios from 'axios';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import config_site from '../../config_site';


import logo from './03.jpg'


function AddDeck(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [deckList, setDeckList] = useState([]);
    const [photoDeck, setPhotoDeck] = useState([]);
    const params = useParams();


    function stringOrNumber(str) {
        if (isNaN(str)) {
            return "string";
        } else {
            return "number";
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (deckList.length > 5) {
            let deck = deckList.split('\n')
            for (let i = 0; i < deck.length; i++) {
                //console.log(deck[i].split(" ")[0] + " is " + stringOrNumber(deck[i].split(" ")[0]))
            }

        }
        const formData = new FormData();
        formData.append('name', form.deck_name.value)
        formData.append('variant_2', null)
        formData.append('sourse', "Регина Олдина")
        formData.append('userbattle', props.item.id)
        formData.append('format', props.event_format)
        formData.append('ivent', params.tid)
        formData.append('variant', -1)
        formData.append('deckList', deckList)
        if (photoDeck !== "[]")
            formData.append('deck_img', photoDeck)
        let url = `${config_site.apiBaseUrl}/api/adddeletedeck/?cmd=add`

        const res = await fetch(url,
            {
                method: 'POST',
                body: formData,
            });
        const datadata = await res.json();
        props.f(true)
    };


    const handleChangeDeckList = (event) => {
        setDeckList(event.target.value)
    }

    const handleChangePhotoDeck = (event) => {
        setPhotoDeck(event.target.files[0])
    }


    //
    return (
        <>
            <Button variant="primary" className="m-1" size="sm" onClick={handleShow}>
                Добавить колоду
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Добавление колоды</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Название колоды</Form.Label>
                            <Form.Control
                                id="deck_name"
                                type="text"
                                placeholder='"Степи-Горы-Леса-Болота-Тьма" или "Очи Кронга"'
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Список карт колоды:</Form.Label>
                            <Form.Control as="textarea" rows={5} id="event_text1"
                                placeholder=' 3 Очи кронга
                                2 Крыселап
                                   или
                                   ВС 123 3
                                   НТ 17 2'  onChange={handleChangeDeckList} />
                        </Form.Group>
                        Более подробно: <a href='' target='_blank'>ТЫК</a><br></br><br></br>
                        <Form.Group className="mb-3" >
                            <Form.Label>Если нет возможности указать список карт, можно приложить фото колоды:</Form.Label>
                            <Form.Control type="file" size="sm" id="event_text2" onChange={handleChangePhotoDeck} />
                        </Form.Group>



                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Закрыть
                        </Button>
                        <Button variant="primary" type='submit'>
                            Добавить
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>


    );
}

export default AddDeck;