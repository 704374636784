

const initialState = {
    club_tournaments: null,
    isLoading: false,
    isDataLoading: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case "GET_CLUB_TOURNAMENTS":
            //console.log("GET_CLUB_TOURNAMENTS")
            return {
                ...state,
                isLoading: true,
                club_tournaments: payload,
            }
        case "USER_LOADED_FAIL":
            //console.log("USER_LOADED_FAIL")
            return {
                ...state,
                isLoading: false,
            }
        case "DATA_LOAD_SUCCESS":
            return {
                ...state,
                isDataLoading: true,
            }
        case "DATA_LOAD_FAIL":
            return {
                ...state,
                isDataLoading: false,
            }

        default:
            //console.log("default")
            return state
    }
};
