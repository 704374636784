import React, { useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

const TiebreakView = ({ data, tipe }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderTableRows = (data) => {
        if (tipe === 1)
            return data.opp.map((item, index) => (
                <React.Fragment key={index}>
                    <tr style={{ backgroundColor: '#ffe28a' }}>
                        <td>{index + 1}</td>
                        <td>{item.id}</td>
                        <td>{item.rez} %</td>
                        <td></td>
                        <td></td>
                        <td>{item.points} =&gt; {item.points} / ({item.opp.length}  * 3) = {Math.round(item.points / (item.opp.length * 3) * 100) / 100}</td>
                    </tr>
                    {item.opp && item.opp.map((oppItem, oppIndex) => (
                        <tr style={{ backgroundColor: '#e2f4c7' }} key={oppIndex}>
                            <td></td>
                            <td></td>
                            <td>{oppIndex + 1}</td>
                            <td>{oppItem.id}</td>
                            <td>{oppItem.rez_w}</td>
                            <td>{oppItem.points}</td>
                        </tr>
                    ))}
                </React.Fragment>
            ))
            if (tipe === 3)
                return data.opp.map((item, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td style={{ backgroundColor: '#ffe28a' }}>{index + 1}</td>
                            <td style={{ backgroundColor: '#ffe28a' }}>{item.id}</td>
                            <td style={{ backgroundColor: '#ffe28a' }}>{item.rez}</td>
                            <td style={{ backgroundColor: '#ffe28a' }}></td>
                            <td style={{textAlign:'right', backgroundColor:'#ffe28a'}}>Сумма: </td>
                            <td style={{ backgroundColor: '#ffe28a' }}>{item.points}</td>
                        </tr>
                        {item.opp && item.opp.map((oppItem, oppIndex) => (
                            <tr key={oppIndex}>
                                <td style={{ backgroundColor: '#e2f4c7' }}></td>
                                <td style={{ backgroundColor: '#e2f4c7' }}></td>
                                <td style={{ backgroundColor: '#e2f4c7' }}>{oppIndex + 1}</td>
                                <td style={{ backgroundColor: '#e2f4c7' }}>{oppItem.id}</td>
                                <td style={{ backgroundColor: '#e2f4c7' }}>{oppItem.rez_w}</td>
                                <td style={{ backgroundColor: '#e2f4c7' }}>{oppItem.points}</td>
                            </tr>
                        ))}
                    </React.Fragment>
                ))
        if (tipe === 2)
            return data.opp.map((item, index) => (
                <React.Fragment key={index}>
                    <tr>
                        <td style={{ backgroundColor: '#ffe28a' }}>{index + 1}</td>
                        <td style={{ backgroundColor: '#ffe28a' }}>{item.id}</td>
                        <td style={{ backgroundColor: '#ffe28a' }}>{item.rez_w}</td>
                        <td style={{ backgroundColor: '#ffe28a' }}>{item.rez}</td>
                    </tr>
                </React.Fragment>
            ));
    };

    return (
        <>
            <div variant="primary" onClick={handleShow}>
                {data.rez}
            </div>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Расчет тай брейков</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        <thead>
                            {tipe === 1 ?
                                <tr>
                                    <th></th>
                                    <th>Оппонент</th>
                                    <th>Результат</th>
                                    <th>Оппонент оппонента</th>
                                    <th>Результат</th>
                                    <th>Количество очков</th>
                                </tr> : <></>}
                            {tipe === 3 ?
                                <tr>
                                    <th></th>
                                    <th>Оппонент</th>
                                    <th>Результат</th>
                                    <th>Оппонент оппонента</th>
                                    <th>Результат</th>
                                    <th>Количество очков</th>
                                </tr> : <></>}
                            {tipe === 2 ?
                                <tr>
                                    <th></th>
                                    <th>Оппонент</th>
                                    <th>Результат</th>
                                    <th>Количество очков</th>
                                </tr> : <></>}
                        </thead>
                        <tbody>
                            {renderTableRows(data)}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TiebreakView;
