import { NavLink, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Card from 'react-bootstrap/Card';
import config_site from '../../config_site';
import { Alert, Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import Bars from "../Charts/Bars";

function CardsSetStatistics(props) {
    const [data, setData] = useState([]);
    const [dataBars1, setBarsData1] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const params = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config_site.apiBaseUrl}/api/CardsSetsStatistics/?sid=${params.id}`);

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error);
                }

                const data_in = await response.json();
                setData(data_in);
                console.log(data_in)
                setIsLoading(false);

                const rez_arr = data_in.data.map(item => {
                    const labels1 = item.data.map(dataItem => `${dataItem.card_name} (${dataItem.card_count})`);
                    const card_count1 = item.data.map(dataItem => dataItem.card_count);
                    return {
                        labels: labels1,
                        datasets: [
                            {
                                label: 'Количество',
                                data: card_count1,
                                backgroundColor: `rgba(${Math.random() * 256}, ${Math.random() * 256}, ${Math.random() * 256}, 0.7)`,
                            },
                        ],
                    };
                });

                setBarsData1(rez_arr);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            setData([]);
            setIsLoading(true);
            setBarsData1({});
        };
    }, [params.month, params.year]);

    return isLoading ? (
        <div>Loading...</div>
    ) : error ? (
        <Alert variant="danger">{error}</Alert>
    ) : (
        <Container className="mt-3">

            
            <Breadcrumb className='mt-3'>
                <Breadcrumb.Item><NavLink to={'/'}>Главная</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item><NavLink to={'/statistics/cards/'}>Статистика по картам</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item><NavLink to={'/statistics/sets/'}>По выпускам</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item active>{data.name}</Breadcrumb.Item>
            </Breadcrumb>

            {data.data.map((item, id) => (
                <Row key={id} className="mt-3">
                    <Row className="mt-0"><Col><h4>{item.text}</h4></Col></Row>
                    <Col md={6} style={{ height: '23rem' }}>
                        <Bars data={dataBars1[id]} />
                    </Col>
                    {item.data.map((item_1, subId) => (
                        <Col key={subId} className="mt-3">
                            <div className="tooltiptooltip_s">
                                <Card style={{ width: '8.8rem' }}>
                                    <Card.Img variant="top" src={`${config_site.apiBaseUrl.replace('8000', '3000')}/src/img/${item_1.card_img}`} />
                                    <Card.Body>
                                        {item_1.card_name}<br />({item_1.card_count})
                                    </Card.Body>
                                </Card>
                                <span className="tooltiptexttooltiptext_s">
                                    <img src={`${config_site.apiBaseUrl.replace('8000', '3000')}/src/img/${item_1.card_img}`} width="300" height="450" style={{ borderRadius: 20 }} />
                                </span>
                            </div>
                        </Col>
                    ))}
                </Row>
            ))}
            <Row className="mt-1">
                <h6>
                    Под "популярными картами" имеются ввиду карты, которые чаще всего встречаются в базе данных.<br />
                    Так как колоды с турниров не всегда вносятся в базу данных, не забываем делать поправку на полноту данных
                </h6>
            </Row>
        </Container>
    );
}

export default CardsSetStatistics;
