import './home.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import News_cards from './News_cards'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Meta from '../MetaSt/Meta';
import SearchForm from '../Search/SearchForm';



let data = [
    ['Новость 1', './src/img/01.jpg', 'Текст', '/news/1'],
    ['Новость 2', './src/img/02.jpg', 'Текст', '/news/2'],
    ['Новость 3', './src/img/03.jpg', 'Текст', '/news/3'],
    ['Новость 4', './src/img/04.jpg', 'Текст', '/news/4']]

function Home(props) {
    return (


        <Container>
   
            <Row>
                <Col className='m-3'><h3>Текущая мета стандарта (по стихиям)</h3></Col>
            </Row>
            <Row  className='m-3'>
                <Col  className='m-3'>
                При достаточном количестве колод с турниров, тут появится актуальная таблица
                </Col>
                <Meta  className='m-3' period='30'/>
            </Row>           
        </Container>
    )
}

export default Home;

/*


                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="home" title="Стандарт (За неделю)">{
                            <Meta period='7'/>
}</Tab>

                        <Tab eventKey="profile" title="Стандарт (За месяц)">{
                            <Meta period='30'/>
}
                        </Tab>
                        <Tab eventKey="contact" title="Стандарт (За полгода)">{
                            <Meta period='180'/>
}
                        </Tab>
                    </Tabs>


                    ----------------------------------------------
<Row>
                <Col>* Значения, указанные в таблице, являются результатом тестового заполнения данных и не отображают реальной картины меты. <br />При достаточном количестве данных появится реальная мета.</Col>
            </Row>
<Row>
                <Col>
                    <div className="block2">
                        {data.map((d) =>
                            <div id={d.id} className="card-news__card">
                                <News_cards
                                    img={d[1]}
                                    title={d[0]}
                                    text={d[2]}
                                    href={d[3]} />
                            </div>
                        )}

                    </div>
                </Col>
            </Row>






*/