import './deck.css';
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import {
    NavLink,
} from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import PureComponent from '../Charts/PureComponent';
import Bars from '../Charts/Bars';
import config_site from '../../config_site'



function Deck(props) {
    const [data, setData] = useState([]);
    const [data_gr, setData_gr] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        fetch(`${config_site.apiBaseUrl}/api/deckView/?deck_id=` + params.id)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);
                let labelsBUF = []
                let backgroundColorBUF = []
                let borderColorBUF = []
                let dataBUF = []
                if (data_in.element_count[0] !== 0) {
                    labelsBUF.push('Равнины')
                    backgroundColorBUF.push('rgba(255, 206, 86, 0.5)')
                    borderColorBUF.push('rgba(255, 206, 86, 1)')
                    dataBUF.push(data_in.element_count[0])
                }
                if (data_in.element_count[1] !== 0) {
                    labelsBUF.push('Горы')
                    backgroundColorBUF.push('rgba(54, 162, 235, 0.5)')
                    borderColorBUF.push('rgba(54, 162, 235, 1)')
                    dataBUF.push(data_in.element_count[1])
                }
                if (data_in.element_count[2] !== 0) {
                    labelsBUF.push('Леса')
                    backgroundColorBUF.push('rgba(75, 192, 192, 0.5)')
                    borderColorBUF.push('rgba(75, 192, 192, 1)')
                    dataBUF.push(data_in.element_count[2])
                }
                if (data_in.element_count[3] !== 0) {
                    labelsBUF.push('Болота')
                    backgroundColorBUF.push('rgba(112, 130, 56, 0.5)')
                    borderColorBUF.push('rgba(112, 130, 56, 1)')
                    dataBUF.push(data_in.element_count[3])
                }
                if (data_in.element_count[4] !== 0) {
                    labelsBUF.push('Тьма')
                    backgroundColorBUF.push('rgba(153, 102, 255, 0.5)')
                    borderColorBUF.push('rgba(153, 102, 255, 1)')
                    dataBUF.push(data_in.element_count[4])
                }
                if (data_in.element_count[5] !== 0) {
                    labelsBUF.push('Нейтралы')
                    backgroundColorBUF.push('rgba(207, 73, 34, 0.5)')
                    borderColorBUF.push('rgba(207, 73, 34, 1)')
                    dataBUF.push(data_in.element_count[5])
                }


                setData_gr({
                    labels: labelsBUF,
                    datasets: [
                        {
                            label: 'Количество карт',
                            data: dataBUF,
                            backgroundColor: backgroundColorBUF,
                            borderColor: borderColorBUF,
                            borderWidth: 1,
                        },
                    ],
                })

            });
            return () => {
                setData([]);
                setIsLoading(true);
                setData_gr({})
              };
    }, []);

    return isLoading || (data === '[]') ? (
        <div>Loading...</div>
    ) : (<div className="container">
        <Breadcrumb className='mt-3'>
            <Breadcrumb.Item><NavLink to={'/'}>Главная</NavLink></Breadcrumb.Item>
            <Breadcrumb.Item><NavLink to={'/decks/'}>Колоды</NavLink></Breadcrumb.Item>
            <Breadcrumb.Item active>{data.name}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="deck">
            <div className='deck-name'>
                {data.name}
                <span className='author'>{data.sourse}</span>
            </div>
            <div className='deck-information'>
                Формат: {data.format === '1' ? "Стандарт" : data.format === '2' ? "Драфт" : data.format === '3' ? "Силед" : "---"}<br></br>
                Источник колоды: {data.sourse}<br></br>
                <br></br>
                Дата добавления: {data.date_add}<br></br><br></br>
            </div>
            <Row>
                <Col xs={8}>
                    <Row>
                        <Col>
                            <div className='deck-name'>
                            Рядовые
                            </div>
                            <div className="card-list">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className='p-1' align="center">Кол-во</td>
                                            <td className='p-1' align="center">Имя карты</td>
                                            <td className='p-1' align="center">Цена</td>
                                            <td className='p-1' align="center">Стихия</td>
                                        </tr>
                                        {

                                            data.deckData.map(item => (
                                                item.cardImg.price_silver > 0 ?
                                                    <tr key={item.id}>
                                                        <td className='p-1' align="center">{item.count}</td>
                                                        <td className='p-1'>
                                                            <div className="tooltiptooltip">{item.cardImg.name}
                                                                <span className="tooltiptexttooltiptext">
                                                                    <img src={"https://test.berserkmeta.ru/src/img/" + item.cardImg.card_img_1} width="250" height="350" style={{ borderRadius: 20 }} />
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className='p-1' align="center">{(item.cardImg.price_gold > 0) ? item.cardImg.price_gold + " " : ""}
                                                            {(item.cardImg.price_gold > 0) ? <img src="/src/img/elements/icon-elite.png" height="20"
                                                                alt="Элитная" title="Элитная"></img> : ""}
                                                            {(item.cardImg.price_silver > 0) ? item.cardImg.price_silver + " " : ""}
                                                            {(item.cardImg.price_silver > 0) ? <img src="/src/img/elements/icon-ordinary.png" height="20"
                                                                alt="Рядовая" title="Рядовая"></img> : ""}
                                                        </td>
                                                        <td className='p-1' align="center">
                                                            {(item.cardImg.element_tipe_0) ? <img src="/src/img/elements/element-plains.png" height="20"
                                                                alt="Степи" title="Степи"></img> : ""}
                                                            {(item.cardImg.element_tipe_1) ? <img src="/src/img/elements/element-mountains.png" height="20"
                                                                alt="Горы" title="Горы"></img> : ""}
                                                            {(item.cardImg.element_tipe_2) ? <img src="/src/img/elements/element-forests.png" height="20"
                                                                alt="Леса" title="Леса"></img> : ""}
                                                            {(item.cardImg.element_tipe_3) ? <img src="/src/img/elements/element-swamps.png" height="20"
                                                                alt="Болота" title="Болота"></img> : ""}
                                                            {(item.cardImg.element_tipe_4) ? <img src="/src/img/elements/element-dark.png" height="20"
                                                                alt="Тьмы" title="Тьмы"></img> : ""}
                                                            {(item.cardImg.element_tipe_5) ? <img src="/src/img/elements/element-neutral.png" height="20"
                                                                alt="Нейтральные" title="Нейтральные"></img> : ""}
                                                        </td>
                                                    </tr> : ""
                                            ))}
                                    </tbody>
                                </table>


                            </div>
                        </Col>
                        <Col>
                            <div className='deck-name'>
                                Элитные
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='p-1' align="center">Кол-во</td>
                                        <td className='p-1' align="center">Имя карты</td>
                                        <td className='p-1' align="center">Цена</td>
                                        <td className='p-1' align="center">Стихия</td>
                                    </tr>
                                    {

                                        data.deckData.map(item => (
                                            item.cardImg.price_gold > 0 ?
                                                <tr key={item.id}>
                                                    <td className='p-1' align="center">{item.count}</td>
                                                    <td className='p-1'>
                                                        <div className="tooltiptooltip">{item.cardImg.name}
                                                            <span className="tooltiptexttooltiptext">
                                                                <img src={"https://test.berserkmeta.ru/src/img/" + item.cardImg.card_img_1} width="250" height="350" style={{ borderRadius: 20 }} />
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className='p-1' align="center">
                                                        {(item.cardImg.price_gold > 0) ? item.cardImg.price_gold + " ": ""}
                                                        {(item.cardImg.price_gold > 0) ? <img src="/src/img/elements/icon-elite.png" height="20"
                                                            alt="Элитная" title="Элитная"></img>: ""}
                                                        {(item.cardImg.price_silver > 0) ? item.cardImg.price_silver + " ": ""}
                                                        {(item.cardImg.price_silver > 0) ? <img src="/src/img/elements/icon-ordinary.png" height="20"
                                                            alt="Рядовая" title="Рядовая"></img> : ""}
                                                    </td>
                                                    <td className='p-1' align="center">
                                                        {(item.cardImg.element_tipe_0) ? <img src="/src/img/elements/element-plains.png" height="20"
                                                            alt="Степи" title="Степи"></img> : ""}
                                                        {(item.cardImg.element_tipe_1) ? <img src="/src/img/elements/element-mountains.png" height="20"
                                                            alt="Горы" title="Горы"></img> : ""}
                                                        {(item.cardImg.element_tipe_2) ? <img src="/src/img/elements/element-forests.png" height="20"
                                                            alt="Леса" title="Леса"></img> : ""}
                                                        {(item.cardImg.element_tipe_3) ? <img src="/src/img/elements/element-swamps.png" height="20"
                                                            alt="Болота" title="Болота"></img> : ""}
                                                        {(item.cardImg.element_tipe_4) ? <img src="/src/img/elements/element-dark.png" height="20"
                                                            alt="Тьмы" title="Тьмы"></img> : ""}
                                                        {(item.cardImg.element_tipe_5) ? <img src="/src/img/elements/element-neutral.png" height="20"
                                                            alt="Нейтральные" title="Нейтральные"></img> : ""}
                                                    </td>
                                                </tr>
                                                : ""





                                        ))}
                                </tbody>
                            </table>


                        </Col>
                    </Row>
                </Col>
                <Col xs={4}>
                    <div >
                        <PureComponent data={data_gr} />

                    </div>
                    <div
                        width={20}>
                        {
                            //<Bars />
                        }

                    </div>
                </Col>
            </Row>

        </div>
    </div>
    );
}
export default Deck;


