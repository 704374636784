const config_site = {
    //apiBaseUrl: 'http://localhost:8000',
    apiBaseUrl: 'https://test.berserkmeta.ru',
    // Добавьте другие настройки по необходимости 
};


export default config_site;


/*
+   1.  РезултТоурнамент разбить на РезТ и ИнфоТ. РезТ загружать по кнопкам
+   2.  В профиля пользователей изменить таблицы и данные
+   3.  Иконки кристаллов в колодах
    4.  Добавление описания карт адекватное
    5.  Добавить карты в БД
    6.  Расширенный поиск карт
    7.  Новости
??? 8.  В описание турниров привести примеры расчетов
    9.  Дать возможность редактирования описания до и после турнира
    10. !!! Промлемы с миграцией баттлыИнИвентс
    11. !!! Промлемы с метатаблицей
+\- 12. Сделать расчет тай брейков под мили
+\- 13. Сделать расчет тай брейков под усеченного Бухгольца
    14. Переход в свой профиль адекватный
+\- 15. Отобразить расчет тай брейков
    16. Добавление по id
    17. Перемешивание игроков после добавления
+\- 18. Двойное начало турнира (+Перенаправление?, -обработка ошибки)
+   19. При равных тай-брейках меняется порядок
        Вычеркивать только после удаления
        Сортировка при определении 3го места
        Лига++ барахлит

    n.  Швейцарка с топом
    +   Разобраться сколько человек в топ идёт
    n+1.Командная игра
    n+2.Смешанная (драфт+редрафт, драфт+стандарт)

*/