import React, { Fragment, useState, useEffect } from 'react';
import { Link, Navigate, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, checkAuthenticated } from '../actions/auth';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const LogInOut = ({checkAuthenticated, logout, isAuthenticated}) => {
    const [redirect, setRedirect] = useState(false);
    const [cookies, setCookie] = useCookies(['access_token', 'refresh_token']);

    useEffect(() => {
        // Проверьте аксесс токен при загрузке компонента
        const checkAccessToken = async () => {
            checkAuthenticated()
        };

        if (isAuthenticated) {
            checkAccessToken();
        }
    }, [cookies, isAuthenticated, setCookie]);

    const logout_user = () => {
        logout();
        setRedirect(false);
    };

    const guestLinks = () => (
        <Fragment>
            <li className='nav-item'>
                <Link className='nav-link' to='/login'>Вход</Link>
            </li>
            <li className='nav-item'>
                <Link className='nav-link' to='/reg'>Регистрация</Link>
            </li>
        </Fragment>
    );

    const authLinks = () => (
        <li className='nav-item'>
            <a className='nav-link' href='#!' onClick={logout_user}>Выход</a>
        </li>
    );

    return (
        <Fragment>
            {isAuthenticated ? authLinks() : guestLinks()}
            {redirect ?
                <Navigate to='/' /> :
                <Fragment></Fragment>}
        </Fragment>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logout, checkAuthenticated })(LogInOut);
