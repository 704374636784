import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from 'react';

import { Navigate } from 'react-router-dom'
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import EditorText from '../Editor/EditorText';
import { EditorState, convertToRaw } from 'draft-js';
import config_site from '../../config_site';

function TournamentsCreate({ is_club_owner, isAuthenticated }) {


    const [variant_distribution_players, setVariant_distribution_players] = useState(0);
    const [dataT, setData] = useState([]);
    const [events_club, setEvents_club] = useState([]);
    const [events_global, setEvents_global] = useState([]);
    const [event_creared, setIsLoading] = useState(false);
    const [events_type, setEventType] = useState(0);
    const [events_range_liga, setEventRangeLiga] = useState("0");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {

        let config = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }

        fetch(`${config_site.apiBaseUrl}/api/eventsforevent/?cmd=evt`, config)
            .then(async response => {
                let data_in = await response.json();
                setEvents_club(data_in.dataC);
                setEvents_global(data_in.dataG);
            });
        return () => {
            setEvents_club([]);
            setEvents_global([]);
        };
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault()
        if (!isButtonDisabled)
            // Блокируем кнопку
            setIsButtonDisabled(true);
        const form = event.currentTarget;

        let data = {
            "event_name": form['event_name'].value,
            "event_text": convertToRaw(editorState.getCurrentContent()),
            "event_koef": Number(form.event_koef.value),
            "event_type": form.event_type.value,
            "event_date": form.event_date.value + "T" + form.event_time.value + ":00+00",
            "event_location": form.event_location.value,
            "event_in_event_1": Number(form.event_in_event_1.value),
            "event_in_event_2": Number(form.event_in_event_2.value),
        }
        if (events_type !== 2) {
            data = {
                "event_name": form['event_name'].value,
                "event_text": convertToRaw(editorState.getCurrentContent()),
                "event_type": form.event_type.value,
                "event_date": form.event_date.value + "T" + form.event_time.value + ":00+00",
                "event_location": form.event_location.value,
                "event_format": form.event_format.value,
                "event_raspred": form.event_raspred.value,
                "event_koef": form.event_koef.value,
                "event_count_deck": form.event_count_deck.value,
                "event_w_ball": form.w.value,
                "event_n_ball": form.n.value,
                "event_l_ball": form.l.value,
                "event_aw_ball": form.aw.value,

                "event_in_event_1": Number(form.event_in_event_1.value),
                "event_in_event_2": Number(form.event_in_event_2.value),
            }
        }
        if (form.event_previous_tournament !== undefined) {
            data["event_previous_tournament"] = form.event_previous_tournament.value == null ? null : form.event_previous_tournament.value
            // event_pl_in_t
            data["event_pl_in_t"] = form.event_pl_in_t.value == null ? null : Number(form.event_pl_in_t.value)
        }

        let config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        }
        //console.log(data)
        fetch(`${config_site.apiBaseUrl}/api/clubevents/`, config)
            .then(async response => {
                let data_in = await response.json();
                //setData(data_in);
                if (data_in.aaa === '200 ok!')
                    setIsLoading(true);
                setIsButtonDisabled(false);

            });
    };

    //
    const raspredChange = (numVar) => {
        setVariant_distribution_players(numVar)

        let config = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }

        if (numVar === 2) {
            fetch(`${config_site.apiBaseUrl}/api/eventsforevent/?cmd=rsp`, config)
                .then(async response => {
                    let data_in = await response.json();
                    setData(data_in.data);
                });
        }
        else
            setData([])
    };

    const eventTypeSet = (event) => {
        setEventType(Number(event.target.value))
    };

    const eventRangeLigaSet = (event) => {
        setEventRangeLiga(event.target.value)
    };

    // 
    if (event_creared) {
        return <Navigate to="/tournaments/club/" />
    }

    return (
        <Container>
            <br></br>
            <Form className="mb-3" onSubmit={handleSubmit}>
                <Row>
                    <Col sm={6}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Название турнира</Form.Label>
                            <Form.Control id="event_name" type="text" />
                        </Form.Group>

                        <Form.Label>Описание турнира</Form.Label>
                        <EditorText placeholder='...Введите текст' editorState={editorState} setEditorState={setEditorState} readOnly={false} />
                        <Form.Group className="mb-3" >
                            <Form.Label>Тип турнира</Form.Label>
                            <Form.Select id="event_type" aria-label="Default select example" onChange={(event) => eventTypeSet(event)}>
                                <option value="0">Обычный</option>
                                <option value="1">Лига</option>
                                <option value="2">Лига+</option>
                                <option value="3">Лига++</option>
                                <option value="4" disabled>Региональный</option>
                                <option value="5" disabled>Региональный+</option>
                                <option value="6" disabled>Региональный++</option>
                                <option value="7" disabled>Гран-при</option>
                                <option value="8" disabled>Гран-при+</option>
                                <option value="9" disabled>Гран-при++</option>
                                <option value="10">Другое</option>
                                <option value="11">Другое+</option>
                                <option value="12">Другое++</option>
                            </Form.Select>
                        </Form.Group>
                        {(events_type !== 2) ?
                            <Form.Group className="mb-3" >
                                <Form.Label>Математика ранжирования участников (тайбрейки)</Form.Label>
                                <Form.Select id="event_koef" aria-label="Default select example">
                                    <option value="0">Как в МТГ</option>
                                    <option value="1">Как на melle</option>
                                    <option value="2">Коэффициент Бухгольца</option>
                                    <option value="3" disabled>Коэффициент Бухгольца + Уср Б-1</option>
                                    <option value="4" disabled>Коэффициент Бухгольца + Ус Б-1</option>
                                    <option value="5" disabled>Коэффициент Бухгольца + Уср Б-1 + Уср Б-2</option>
                                    <option value="6" disabled>Коэффициент Бухгольца + Ус Б-1 + Ус Б-2</option>
                                    <option value="7" disabled>Коэффициент Бергера</option>
                                    <option value="8" disabled>Коэффициент Бухгольца + Коэффициент Бергера</option>
                                    <option value="9" disabled>Предложить</option>
                                </Form.Select>
                            </Form.Group> :
                            <Form.Group className="mb-3" >
                                <Form.Label>Математика ранжирования участников</Form.Label>
                                <Form.Select id="event_koef" aria-label="Default select example" onChange={(event) => eventRangeLigaSet(event)}>
                                    <option value="0">Результаты игр + Участие</option>
                                    <option value="1">Топ-2 + Участие</option>
                                    <option value="2">Победа - 3, остальные - 1</option>
                                    <option value="3">Теодорих</option>
                                </Form.Select>
                            </Form.Group>}

                        {(events_type !== 2) ?
                            <Form.Group className="mb-3" >
                                <Form.Label>Формат турнира</Form.Label>
                                <Form.Select id="event_format" aria-label="Default select example">
                                    <option value="1">Стандарт</option>
                                    <option value="2">Драфт</option>
                                    <option value="3">Силед</option>
                                </Form.Select>
                            </Form.Group> :
                            <></>}
                        <div style={{ display: 'flex' }}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Дата проведения</Form.Label>
                                <Form.Control id="event_date" type="date" defaultValue={"2024-05-01"} />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Время начала</Form.Label>
                                <Form.Control id="event_time" type="time" defaultValue={"15:00"} />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Место проведения</Form.Label>
                                <Form.Control id="event_location" type="text" />
                            </Form.Group>
                        </div>

                    </Col>
                    <Col>

                        {(events_type !== 2) ?
                            <Form.Group className="mb-3" >
                                <Form.Label>Способ распределения пар</Form.Label>
                                <Form.Select id="event_raspred" aria-label="Default select example">
                                    <option value="0">Швейцарская система</option>
                                    <option value="1">Олимпийская система</option>
                                    <option value="2">Швейцарская система с топом</option>
                                        
                                    <option value="3" disabled>Случайный</option>
                                    <option value="4" disabled>Ручной</option>
                                </Form.Select>
                            </Form.Group> :
                            <></>}

                        {(events_type !== 2) ? <>
                            <div className="mb-3" >
                                <Form.Check // prettier-ignore
                                    type="radio"
                                    id="1"
                                    name="group1"
                                    label="Обычное"
                                    onChange={() => raspredChange(0)}
                                />
                                <Form.Check // prettier-ignore
                                    type="radio"
                                    id="2"
                                    name="group1"
                                    label="Распределение на основе предыдущих игр"
                                    onChange={() => raspredChange(1)}
                                    disabled
                                />

                                <Form.Check // prettier-ignore
                                    type="radio"
                                    id="3"
                                    name="group1"
                                    label="Распределение на основе конкретной игры"
                                    onChange={() => raspredChange(2)}
                                //disabled
                                />
                            </div>
                            {variant_distribution_players === 2 ?
                                <>
                                    <Form.Label>Выберите турнир:</Form.Label>
                                    <Form.Select id="event_previous_tournament" aria-label="Default select example">
                                        {dataT.map((item) => (
                                            <option value={item.id}>{item.event_name}. Дата: {item.event_date.split('T')[0]}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Категория игроков в новый турнир</Form.Label>
                                        <Form.Select id="event_pl_in_t" aria-label="Default select example" onChange={(event) => eventRangeLigaSet(event)}>
                                            <option value="0">Все</option>
                                            <option value="1">Топ-2</option>
                                            <option value="2">Топ-4</option>
                                            <option value="3">Топ-8</option>
                                            <option value="4">Топ-16</option>
                                            <option value="5">Топ-32</option>
                                            <option value="6">Топ-64</option>
                                            <option value="7">Топ-128</option>
                                        </Form.Select>
                                    </Form.Group>
                                </> :
                                ""}
                            <></>
                            <Form.Label className="m-3">Количество очков в зависимости от результата</Form.Label>
                            <div style={{ display: 'flex' }}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Победа</Form.Label>
                                    <Form.Control id="w" type="number" defaultValue={3} />
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Ничья</Form.Label>
                                    <Form.Control id="n" type="number" defaultValue={1} />
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Проигрыш</Form.Label>
                                    <Form.Control id="l" type="number" defaultValue={0} />
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Автопобеда</Form.Label>
                                    <Form.Control id="aw" type="number" defaultValue={3} />
                                </Form.Group>
                            </div>

                            <Form.Group className="mb-3" >
                                <Form.Label>Количество колод турнира</Form.Label>
                                <Form.Select id="event_count_deck" aria-label="Default select example">
                                    <option value="1">Одна</option>
                                    <option value="2">Две</option>
                                    <option value="3">Три</option>
                                </Form.Select>
                            </Form.Group>
                            
                        </> :
                            <><Form.Label className="m-3">Количество очков в зависимости от результата</Form.Label>
                                {events_range_liga === "0" ? <div style={{ display: 'flex' }}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Победа</Form.Label>
                                        <Form.Control id="w" type="number" defaultValue={3} key={1} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Автопобеда</Form.Label>
                                        <Form.Control id="aw" type="number" defaultValue={3} key={2} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Ничья</Form.Label>
                                        <Form.Control id="n" type="number" defaultValue={1} key={3} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Участие</Form.Label>
                                        <Form.Control id="l" type="number" defaultValue={1} key={4} />
                                    </Form.Group>
                                </div> : events_range_liga === "1" ?
                                    <div style={{ display: 'flex' }}>
                                        <Form.Group className="mb-3" key={11}>
                                            <Form.Label>Топ-1</Form.Label>
                                            <Form.Control id="w" type="number" defaultValue={3} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" key={12}>
                                            <Form.Label>Топ-2</Form.Label>
                                            <Form.Control id="aw" type="number" defaultValue={1} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" key={13}>
                                            <Form.Label>Остальные</Form.Label>
                                            <Form.Control id="n" type="number" defaultValue={0} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" key={14}>
                                            <Form.Label>Участие</Form.Label>
                                            <Form.Control id="l" type="number" defaultValue={1} />
                                        </Form.Group>
                                    </div> : <></>}</>}

                                    <Form.Group className="mb-3" >
                                <Form.Label>Часть события Клуба</Form.Label>
                                <Form.Select id="event_in_event_1" aria-label="Default select example">
                                    <option value="0">-</option>
                                    {events_club.map((item) => (
                                        <option value={item.id}>{item.event_name}. Дата: {item.event_date.split('T')[0]}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Часть глобального события № 1</Form.Label>
                                <Form.Select id="event_in_event_2" aria-label="Default select example">
                                    <option value="0">-</option>
                                    {events_global.map((item) => (
                                        <option value={item.id}>{item.event_name}. Дата: {item.event_date.split('T')[0]}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Часть глобального события № 2</Form.Label>
                                <Form.Select id="in_t_2" aria-label="Default select example">
                                    <option value="0">-</option>
                                    {events_global.map((item) => (
                                        <option value={item.id}>{item.event_name}. Дата: {item.event_date.split('T')[0]}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                    </Col>
                </Row>

                <Button type="submit" disabled={isButtonDisabled} variant="outline-success">{isButtonDisabled ? 'Подождите...' : 'Сохранить'}</Button>
            </Form>
            <Row >

            </Row>
        </Container>
    );
}
//export default Tournaments;


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    is_club_owner: state.auth.is_club_owner
});

export default connect(mapStateToProps, null)(TournamentsCreate);
