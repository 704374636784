import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import config_site from '../../config_site';

import {
    NavLink,
} from 'react-router-dom';
import { Breadcrumb, Col, Container, Row, Table } from 'react-bootstrap';

function ClubsStatistics(props) {
    const [data, setData] = useState("[]");
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        fetch(`${config_site.apiBaseUrl}/api/ClubsStatistics/`)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);
            });
        return () => {
            setData("[]");
            setIsLoading(true);
        };
    }, []);

    return isLoading || (data === "[]") ? (
        <div>Loading...</div>
    ) : (
        <Container className="mt-3">
            <Breadcrumb className='mt-3'>
                <Breadcrumb.Item><NavLink to={'/'}>Главная</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item active>Статистика по клубам</Breadcrumb.Item>
            </Breadcrumb>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td className='p-1' align="center">#</td>
                        <td className='p-1' align="center">Название клуба</td>
                        <td className='p-1' align="center">Город</td>
                        <td className='p-1' align="center">Количество турниров</td>
                        <td className='p-1' align="center">Количество колод</td>
                        <td className='p-1' align="center">Процент заполнения</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, id) => (
                            <tr>
                                <td className='p-1' align="center">{id + 1}</td>
                                <td className='p-1' align="center"><NavLink to={'/clubs/' + item.id}>{item.club_name}</NavLink></td>
                                <td className='p-1' align="center">{item.club_countre}</td>
                                <td className='p-1' align="center">{item.countTournaments}</td>
                                <td className='p-1' align="center">{item.countDecks}</td>
                                <td className='p-1' align="center">{100}% / {96}% / {38}%</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </Container>
    );
}
export default ClubsStatistics;


