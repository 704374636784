import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { load_club_tournaments } from '../../actions/berserk';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Spinner from 'react-bootstrap/Spinner';
import {
    NavLink,
} from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import config_site from '../../config_site';

import { useDispatch } from 'react-redux'; // подключаем хук для диспатча


function TournamentsClub({ isAuthenticated, is_club_owner }) {
    const [data, setData] = useState("[]");
    const colors = {
        0: "#addebb", // Обычный турнир  //badbad
        1: "#fdc38c", // Турнир в рамках Лиги
        2: "#ff944d", // Лига+
        3: "#8cb9fd", // Турнир в рамках Регионала
        4: "#8ba3da", // Регионал+
        5: "#ffd1dc", // ГранПри
        6: "#ff7777", // ГранПри+?!
    }

    useEffect(() => {
        if (localStorage.getItem('access')) {
            //if(loadis_club_ownerOnJWT()){}
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                    'Accept': 'application/json'
                }
            };
            fetch(`${config_site.apiBaseUrl}/api/clubevents/`, config)
                .then(async response => {
                    let data_in = await response.json();
                    if (data_in.code !== "token_not_valid"){
                        setData(data_in);
                    }  
                    if (data_in.code === "token_not_valid"){
                        setData({
                            code_e:"token_not_valid"});
                    }
                    
                    
                });
            return () => {
                setData("[]");
            };
        }
    }, [isAuthenticated]);



    return data === "[]" ? (
        <Spinner animation="border" variant="success" />
    )  : data.code_e?<>Необходимо авторизоваться???{console.log(data)}</>:(
        <Container>
            <Row className='mt-4'>
                <Col sm={10}></Col>
                <Col sm={2} className="mb-3">
                    {is_club_owner ? <Button as={NavLink} to="/tournaments/create/" variant="success" size="lg">Создать турнир</Button> : ''}
                </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th colSpan={4}>Текущие турниры</th>
                            </tr>
                            <tr>
                                <th style={{ width: 100 }} className="align-middle">Дата</th>
                                <th className="align-middle">Название</th>
                                <th className="align-middle" width="1rm" >Количество заявок</th>
                                <th className="align-middle" width="1rm">Код</th>
                            </tr>
                        </thead>
                        <tbody>

                            {data.data2.map(item => (
                                <tr key={item.id}>
                                    <th style={{ backgroundColor: colors[item.event_type] }}>{item.event_date.split('T')[0]}</th>
                                    <td align="left"><NavLink to={item.is_tour_started ? "/tournaments/run/" + item.id : "/tournaments/club/" + item.id}>{item.event_name}</NavLink></td>
                                    <th>0</th>
                                    <th>{item.event_code}</th>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </Col>
                <Col sm={6}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th colSpan={4}>Прошедшие турниры</th>
                            </tr>
                            <tr>
                                <th style={{ width: 100 }} className="align-middle">Дата</th>
                                <th className="align-middle">Название</th>
                                <th className="align-middle" width="1rm">Количество участников</th>
                                <th className="align-middle" width="1rm">Количество учтенных колод</th>
                            </tr>
                        </thead>
                        <tbody>

                            {data.data1.map(item => (

                                <tr key={item.id}>
                                    <th style={{ backgroundColor: colors[item.event_type] }}>{item.event_date.split('T')[0]}</th>
                                    <td align="left"><NavLink to={"/tournaments/run/" + item.id}>{item.event_name}</NavLink></td>
                                    <th>0</th>
                                    <th>0</th>
                                </tr>

                            ))}

                        </tbody>
                    </Table>




                </Col>
            </Row>
        </Container>
    );
}
//export default Tournaments;


function mapStateToProps(state) {
    return {
        data: state.berserk.club_tournaments,
        isLoading: state.berserk.isLoading,
        is_club_owner: state.auth.is_club_owner,
        isAuthenticated: state.auth.isAuthenticated
    }
};

export default connect(mapStateToProps, load_club_tournaments)(TournamentsClub);
