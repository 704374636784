import { Navigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Table from "react-bootstrap/esm/Table";
import { Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import Page404 from "../../Pages/Page404";
import AddUserModal from "./AddUserModal";
import axios from "axios";
import AddPrizes from "./AddPrizes";
import config_site from '../../config_site';

function TournamentsCreate({ is_club_owner, isAuthenticated }) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [data, setData] = useState("[]");
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isStart, setIsStart] = useState(false);
    const [countTours, setCountTours] = useState(0);
    const [countUserTours, setCountUserTours] = useState(1);
    const [countUserTop, setCountUserTop] = useState(0);
    const [is_battle_third, setis_battle_third] = useState(false);
    const [error, setError] = useState(null);
    const params = useParams();

    let url = `${config_site.apiBaseUrl}/api/events/?id=` + params.tid;
    let url2 = `${config_site.apiBaseUrl}/api/user_request/?t_id=` + params.tid;

    useEffect(() => {
        const savedCountUserTours = localStorage.getItem('countUserTours');
        if (savedCountUserTours) {
            setCountUserTours(Number(savedCountUserTours));
        }

        Promise.all([
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                    'Accept': 'application/json'
                },
            }),
            fetch(url2)
        ])
            .then(async responses => {
                const [response1, response2] = responses;

                if (!response1.ok) {
                    const errorData = await response1.json();
                    throw new Error(errorData.error);
                }

                if (!response2.ok) {
                    const errorData = await response2.json();
                    throw new Error(errorData.error);
                }

                let data_in1 = await response1.json();
                let data_in2 = await response2.json();
                setIsStart(data_in1.is_started);
                setData(data_in1);
                setUsers(data_in2);
                setCountTours(Math.ceil(Math.log2(data_in2.data2.length)));
                setIsLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setIsLoading(false);
            });

        return () => {
            setUsers([]);
            setData([]);
            setIsLoading(true);
        };
    }, []);

    const onClick = (item) => {
        let config = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        };

        url = `${config_site.apiBaseUrl}/api/user_request/?t_id=` + params.tid + '&id=' + item.target.value;
        fetch(url, config).then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.error);
                return;
            }

            let data_in2 = await response.json();
            setUsers(data_in2);
            setCountTours(Math.ceil(Math.log2(data_in2.data2.length)));
            setIsLoading(false);
        }).catch(err => {
            setError(err.message);
        });
    };

    const onClick2 = (item) => {
        console.log(is_battle_third)
        if (users.data2.length >= Math.pow(2, countUserTop)) {
            if (!isButtonDisabled)
                setIsButtonDisabled(true);

            let config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                    'Accept': 'application/json'
                },
            };

            url = `${config_site.apiBaseUrl}/api/battles/?cmd=start&t_id=` + params.tid;

            axios.post(url, {
                filedata: {
                    "event_count_tour": countUserTours,
                    "event_count_tour_top": countUserTop,
                    "is_battle_third": is_battle_third
                }
            }, config)
                .then((response) => {
                    if (response.data.rezult === "0k!") {
                        setIsStart(true);
                    } else {
                        throw new Error(response.data.error);
                    }
                })
                .catch((err) => {
                    setError(err.message);
                }).finally(() => {
                    setIsButtonDisabled(false);
                });
        }
    };

    const handleCountToursChange = (e) => {
        const value = e.target.value;
        setCountUserTours(value);
        localStorage.setItem('countUserTours', value);
    };

    return isStart ? <Navigate to={'/tournaments/run/' + params.tid} /> : isLoading ? (
        <div>Loading...</div>
    ) : error ? (
        <Alert variant="danger">{error}</Alert>
    ) : !is_club_owner ? (
        <Page404 />
    ) : (
        <Container>
            <Row>
                <Col sm={4}>
                    Информация о турнире<br />
                    <Form.Group className="mb-3">
                        <Form.Label>Название турнира</Form.Label>
                        <Form.Control id="event_name" type="text" defaultValue={data.event_name} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Описание турнира</Form.Label>
                        <Form.Control id="event_text" as="textarea" rows={3} defaultValue={data.event_text}></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Формат турнира</Form.Label>
                        <Form.Select id="event_format" aria-label="Default select example" defaultValue={data.event_format}>
                            <option value="1">Стандарт</option>
                            <option value="2">Драфт</option>
                            <option value="3">Силед</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Дата проведения</Form.Label>
                        <Form.Control id="event_date" type="text" defaultValue={data.event_date} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Место проведения</Form.Label>
                        <Form.Control id="event_location" type="text" defaultValue={data.event_location} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Способ распределения пар</Form.Label>
                        <Form.Select id="event_raspred" aria-label="Default select example">
                            <option value="0">Швейцарка</option>
                            <option value="1">Олимпийская система</option>
                            <option value="2" disabled>Случайный</option>
                            <option value="3" disabled>Ручной</option>
                        </Form.Select>
                    </Form.Group>
                    <div className="mb-3">
                        <Form.Check
                            type="radio"
                            id="12312"
                            name="group1"
                            label="Распределение на основе предыдущих игр"
                        />
                        <Form.Check
                            type="radio"
                            id="123efw12"
                            name="group1"
                            label="Распределение на основе конкретной игры"
                        />
                    </div>
                    <Form.Label>Выберите турнир:</Form.Label>
                    <Form.Select disabled aria-label="Default select example">
                        <option value="0">Турнир</option>
                        <option value="1">Турнир</option>
                        <option value="2">Турнир</option>
                    </Form.Select>
                    {data.event_code}<br />
                    {data.event_raspred}<br />
                    {data.event_previous_tournament}
                    <Button variant="success" size="lg" disabled>Обновить данные</Button>
                </Col>
                {data.event_type < 2 ? (
                    <>
                        <Col sm={4}>
                            Поданные заявки
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>№</th>
                                        <th>Участник</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.data1.map((item, index) => (
                                        <tr key={index}>
                                            <th>{index + 1}</th>
                                            <th>{item.first_name} {item.user_id ? <>#{item.user_id}</> : <></>}</th>
                                            <th>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    value={item.id}
                                                    onClick={onClick}
                                                >+</Button>
                                            </th>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col sm={4}>
                            Зарегистрированные участники
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>№</th>
                                        <th>Участник</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.data2.map((item, index) => (
                                        <tr key={index}>
                                            <th>{index + 1}</th>
                                            <th>{item.first_name} {item.user_id ? <>#{item.user_id}</> : <></>}</th>
                                            <th>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    value={item.id}
                                                    onClick={onClick}
                                                >-</Button>
                                            </th>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <AddUserModal addUser={setUsers} setCountTours={setCountTours} /><br />
                            <div className="mt-3">
                                <Form.Label>Выберите количество раундов:</Form.Label>
                                <Form.Control
                                    id="event_count_tour"
                                    type="number"
                                    defaultValue={String(countUserTours)}
                                    onChange={handleCountToursChange}
                                />
                                Рекомендуемое количество раундов: {countTours > 0 ? countTours : "-"}
                            </div>
                            <div className="mt-3">
                                {data.event_raspred === 2 ?
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Категория игроков в ТОП</Form.Label>
                                        <Form.Select id="event_count_tour_top" onChange={(event) => { setCountUserTop(event.target.value) }}>
                                            <option value="0">-</option>
                                            <option value="1">Топ-2</option>
                                            <option value="2">Топ-4</option>
                                            <option value="3">Топ-8</option>
                                            <option value="4">Топ-16</option>
                                            <option value="5">Топ-32</option>
                                            <option value="6">Топ-64</option>
                                            <option value="7">Топ-128</option>
                                        </Form.Select>
                                    </Form.Group> : <></>
                                }
                                {countUserTop > 1?
                                    <Form.Check
                                        id="is_battle_third"
                                        name="is_battle_third"
                                        disabled
                                        label="Определение третьего места"
                                        onChange={(event) => { setis_battle_third(event.target.checked)}}
                                    /> : <></>
                                }
                            </div>
                        </Col>
                    </>
                ) : (
                    <Col>
                        <Row>
                            <AddPrizes />
                        </Row>
                        <Row>
                            Учет купленных бустеров
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Участник</th>
                                        <th>Бустер выпуска № 1</th>
                                        <th>Бустер выпуска № 2</th>
                                        <th>Бустер выпуска № 3</th>
                                        <th>Итого</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Фёдор Сумкин</th>
                                        <th><input type="number" defaultValue={0}></input></th>
                                        <th><input type="number" defaultValue={0}></input></th>
                                        <th><input type="number" defaultValue={0}></input></th>
                                        <th>38</th>
                                    </tr>
                                    <tr>
                                        <th>Пендальф Серый</th>
                                        <th><input type="number" defaultValue={0}></input></th>
                                        <th><input type="number" defaultValue={0}></input></th>
                                        <th><input type="number" defaultValue={0}></input></th>
                                        <th>69</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                )}
            </Row>
            <Button variant="success" size="lg" onClick={onClick2} disabled={isButtonDisabled}>{isButtonDisabled ? 'Подождите...' : 'Начать турнир'}</Button>
        </Container>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    is_club_owner: state.auth.is_club_owner
});

export default connect(mapStateToProps, null)(TournamentsCreate);
