import React from "react";
import "draft-js/dist/Draft.css";
import { Editor, EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import {
  toggleBold,
  toggleItalic,
  toggleUnderline,
  isBold,
  isItalic,
  isUnderline,
  initialStyleMap,
} from 'contenido';
import decorator from './decorators'; // Импортируем декоратор
import "./styles.css";

const buttons = [
  { title: 'B', handler: toggleBold, checker: isBold },
  { title: 'I', handler: toggleItalic, checker: isItalic },
  { title: 'Подчеркивание', handler: toggleUnderline, checker: isUnderline },
];

function EditorText(props) {
  return (
    <div>
      <div>
        {!props.readOnly ? buttons.map((button) => (
          <button
            key={button.title}
            type="button"
            style={{
              minWidth: '2rem',
              padding: '0.5rem',
              backgroundColor: button.checker(props.editorState)
                ? '#4cb5f5'
                : 'rgba(125, 125, 125, 0.25)',
              color: button.checker(props.editorState) ? '#fff' : 'inherit',
              borderRadius: '0.5rem',
              border: 'none',
              cursor: 'pointer',
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              button.handler(props.editorState, props.setEditorState);
            }}
          >
            {button.title}
          </button>
        )) : <></>}
      </div>

      <div
        style={!props.readOnly ? {
          border: '1px solid #252525',
          borderRadius: '0.5rem',
          padding: '0.5rem 1rem',
        } : {}}
      >
        <Editor
          editorState={props.editorState}
          onChange={props.setEditorState}
          customStyleMap={initialStyleMap}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          decorators={[decorator]} // Используем декоратор
        />
      </div>
    </div>);
}

export default EditorText;
