import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import {
    NavLink,
} from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Tabs from "react-bootstrap/esm/Tabs";
import { Tab } from "bootstrap";
import Table from "react-bootstrap/esm/Table";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import Tournaments from "./Tournaments";

function TournamentsPage({ is_club_owner, isAuthenticated }) {


    return (
    <Container>
    <Breadcrumb className='mt-3'>
                <Breadcrumb.Item><NavLink to={'/'}>Главная</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item active>Турниры</Breadcrumb.Item>
            </Breadcrumb>
        <Row  className='mt-4'>
            <Col sm={10}></Col>
            <Col sm={2} >
                {is_club_owner?<Button as={NavLink} to="/tournaments/club/" variant="success" size="lg">Турниры клуба</Button>:''}
            </Col>
        </Row>
        <Tournaments/>
    </Container>

    );
}
//export default Tournaments;


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    is_club_owner: state.auth.is_club_owner
});

export default connect(mapStateToProps, null)(TournamentsPage);
