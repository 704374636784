import React, { useState } from 'react';

function Logintest() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    // Здесь вы можете добавить логику проверки email и пароля
    // и выполнить процесс авторизации
  };

  return (
    <div className="login-container">
      <h2>Авторизация</h2>
      <div>
        <label>Email:
          <input type="email" value={email} onChange={handleEmailChange} />
        </label>
      </div>
      <div>
        <label>Пароль:
          <input type="password" value={password} onChange={handlePasswordChange} />
        </label>
      </div>
      <button onClick={handleLogin}>Войти</button>
    </div>
  );
}

export default Logintest;
