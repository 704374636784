import React, { useEffect, useState, useRef } from "react";
import config_site from '../../config_site';
import { Image, Table } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import './cards.css'; // Подключаем стили

function Cards(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [viewType, setViewType] = useState('gallery'); // Добавляем состояние для типа отображения
    const [selectedFoil, setSelectedFoil] = useState(''); // Добавляем состояние для фильтра по фойлению
    const params = useParams();

    const headersRef = useRef({});

    useEffect(() => {
        setIsLoading(true);
        fetch(`${config_site.apiBaseUrl}/api/CardsView/?release=` + params.sid)
            .then(async response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('There was a problem with fetching data:', error);
                setIsLoading(false);
            });
    }, [params.sid]);

    const handleChangeType = (event) => {
        setViewType(event.target.value);
    };

    const handleChangePrintType = (event) => {
        setSelectedType(event.target.value);
        // Scroll to the selected type section
        if (headersRef.current[event.target.value]) {
            headersRef.current[event.target.value].scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="container">
            <div className="m-2" style={{ display: "flex" }}>
                <div className="dropdown m-2">
                    <label htmlFor="printTypeDropdown">Тип печати:&nbsp;&nbsp;</label>
                    <select id="printTypeDropdown" value={selectedType} onChange={handleChangePrintType}>
                        <option value="">Выберите тип печати</option>
                        {data.map((item, index) => (
                            <option key={index} value={item.type_ptint}>{item.type_ptint}</option>
                        ))}
                    </select>
                </div>

                <div className="dropdown m-2">
                    <label htmlFor="viewTypeDropdown">Отображение:&nbsp;&nbsp;</label>
                    <select id="viewTypeDropdown" value={viewType} onChange={handleChangeType}>
                        <option value="gallery">Галерея</option>
                        <option value="table">Таблица</option>
                    </select>
                </div>
                <div><button>Расширенный поиск</button></div>
            </div>
            <div className="content">
                {viewType === 'gallery' && (
                    <div className="decks-list">
                        {data.map((item, index) => (
                            <div key={index} ref={ref => { headersRef.current[item.type_ptint] = ref; }}>
                                <h2>{item.type_ptint}</h2>
                                {item.carsds.map((card, cardIndex) => (
                                    <div key={cardIndex} className="card-container m-1">
                                        <Image src={`/src/img/${card.card_img_1}`} className="card-image" />
                                        {card.foil === 1 && <Image src="/src/img/foil_1.png" className="overlay-image" />}
                                        {card.foil === 2 && <Image src="/src/img/foil_2.png" className="overlay-image" />}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}

                {viewType === 'table' && (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Релиз</th>
                                <th>Номер карты</th>
                                <th>Назввание карты</th>
                                <th>Стоимость</th>
                                <th>Тип</th>
                                <th>Раритетность</th>
                                <th>Тип печати</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                item.carsds.map((card, cardIndex) => (
                                    <tr key={cardIndex}>
                                        <td>-</td>
                                        <td>{card.card_number}</td>
                                        <td>{card.name}</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>{card.rarity}</td>
                                        <td>-</td>
                                        <td>{item.type_ptint}</td>
                                    </tr>
                                ))
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </div>
    );
}

export default Cards;

// <td><Image src={`/src/img/${card.card_img_1}`} style={{ width: '5rem' }} /></td>
