import axios from 'axios';
import jwt_decode from "jwt-decode";
import config_site from '../config_site';

const loadis_club_ownerOnJWT = () =>{
    let rez = localStorage.getItem('access')
    if (rez !== null) {
        let now = new Date();
        let nowS = now.getTime()/1000
        var decoded = jwt_decode(rez);
        console.log("decoded:")
        console.log(decoded)
        if(now >= decoded['exp']){
            
        }
    }

    return true
}

export const load_club_tournaments = () => async dispatch => {
    if (localStorage.getItem('access')) {
        if(loadis_club_ownerOnJWT()){}
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }; 

        try {
            const res = await axios.get(`${config_site.apiBaseUrl}/api/clubevents/`, config);
            dispatch({
                type: "GET_CLUB_TOURNAMENTS",
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: "USER_LOADED_FAIL"
            });
            dispatch({
                type: "AUTHENTICATED_FAIL"
            });

            
        }
    } else {
        dispatch({
            type: "USER_LOADED_FAIL"
        });
    }
};


