import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import { useParams } from "react-router-dom";
import config_site from '../../config_site';
import TableRezult from "./TableRezult";

function TournamentsRunTableRezult(props) {
    const params = useParams();
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [selectedFile, setSelectedFile] = useState({});
    const [data, setData] = useState("[]");

    useEffect(() => {
        let config = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        }

        let url = `${config_site.apiBaseUrl}/api/RunRunTournament/?t_id=` + params.tid
        fetch(url, config).then(async response => {
            let data_in2 = await response.json();
            setData(data_in2);
            props.setisLoading(false);

        });
        return () => {
            setData("[]")
        };
    }, [])

    const onClick2 = async (item) => {
        let url = `${config_site.apiBaseUrl}/api/battles/?cmd=end_raund&t_id=` + params.tid

        let dadadata = {}

        let count = 0;
        for (let key in selectedFile) {
            selectedAnswers[key]['table_photo'] = selectedFile[key]['table_photo']
        }

        for (let key in selectedAnswers) {
            count++
        }


        const formData = new FormData();
        formData.append('dataBattle', selectedAnswers)

        let config = {
            method: 'POST',
            body: JSON.stringify(selectedAnswers),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        }
        const res = await fetch(url, config);
        props.setisLoading(false)
    }




    const endRaund = async (item) => {
        let url = `${config_site.apiBaseUrl}/api/RunRunTournament/?cmd=e_r&t_id=` + params.tid
        //console.log("---------------------------------------------------------------------")
        //console.log("Шо имеем:")

        //console.log(props.data.data)
        //console.log("Заходим в цикл")
        let cccc = 0;
        for (let iii = 0; iii < data.data.length; iii++) {
            //console.log("В цикле")
            if (data.data[iii].table === 0) {
                //console.log("Нашли нулевой стол")
                let numb = 2 * data.data.length + cccc;
                selectedAnswers["keys"] += "," + numb
                selectedAnswers[numb] = {
                    "tour": data.event_tour,
                    "battle_result_w": 0,
                    "battle_result_n": 0,
                    "battle_result_l": 0,
                    "battle_aw_ball": 1,
                    "event": Number(params.tid),
                    "userbattle_1": data.data[iii].player1_id,
                    "userbattle_2": -1,
                }
                cccc++;
            }
        }

        //console.log("---------------------------------------------------------------------")
        //console.log(selectedAnswers)
        //console.log("---------------------------------------------------------------------")

        let config = {
            method: 'POST',
            body: JSON.stringify(selectedAnswers),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        }

        //console.log(config)

        const res = await fetch(url, config);

        //console.log(res)
        props.setIsUserOut(false)

        props.setisLoading(true)
    }



    return data == "[]" ? <>okjuiytyredftgyhujik</> : (
        //  className="mb-3"
        <Row >
            <Col className="p-3"><h3>{
                //data.info
            }</h3></Col>
            <Col sm={12}>
                {data.data.map((item, index) => (
                    item.table === 0 ?
                        <Alert variant={'success'} key={index}>
                            <Col><h4>Стол № {item.table}</h4><br></br>
                                {item.player1} - Автопобеда
                            </Col>
                        </Alert> :
                        <TableRezult key={index} item={item} index={2 * index} selectedAnswers={selectedAnswers}
                            setSelectedAnswers={setSelectedAnswers} setSelectedFile={setSelectedFile} event_tour={data.event_tour}/>
                ))}
            </Col>
            <Button variant="success" size="lg" onClick={endRaund}>Завершить раунд</Button>
        </Row>
    );
}

export default TournamentsRunTableRezult;
