import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import { useParams } from "react-router-dom";
import config_site from '../../config_site';

function TableRezult(props) {
    const params = useParams();
    const [checkedRezult, setCheckedRezult] = useState(false);
    const [checkedDeck1, setCheckedDeck1] = useState(false);
    const [checkedDeck2, setCheckedDeck2] = useState(false);



    const handleOptionChange = (numTable, nunRadio, datapl, tour) => {
        props.setSelectedAnswers(prevSelectedAnswers => ({
            ...prevSelectedAnswers,
            "keys": prevSelectedAnswers["keys"] + "," + numTable + "," + (numTable + 1) + "",
            [numTable]: {
                "tour": tour,
                "battle_result_w": nunRadio === 1 ? 1 : 0,
                "battle_result_n": nunRadio === 2 ? 1 : 0,
                "battle_result_l": nunRadio === 3 ? 1 : 0,
                "event": Number(params.tid),
                "userbattle_1": datapl.player1_id,
                "userbattle_2": datapl.player2_id,
                "pl1_id": datapl.id1,
                "pl2_id": datapl.id2,
            },
            [numTable + 1]: {
                "tour": tour,
                "battle_result_w": nunRadio === 3 ? 1 : 0,
                "battle_result_n": nunRadio === 2 ? 1 : 0,
                "battle_result_l": nunRadio === 1 ? 1 : 0,
                "event": Number(params.tid),
                "userbattle_1": datapl.player2_id,
                "userbattle_2": datapl.player1_id,
                "pl1_id": datapl.id2,
                "pl2_id": datapl.id1,
            },
        }));



    };


    const handleFileChange = (event, numTable) => {


        props.setSelectedFile(selectedFile => ({
            ...selectedFile,
            [numTable]: {
                "table_photo": event.target.files[0],
            },
        }));

    };

    // Выбранные колоды
    const handleOptionDecksChange1 = (numTable, deck_id) => {
        props.selectedAnswers[numTable]["deck_1"] = deck_id
        props.selectedAnswers[numTable + 1]["deck_2"] = deck_id
    };
    // Выбранные колоды
    const handleOptionDecksChange2 = (numTable, deck_id) => {
        props.selectedAnswers[numTable]["deck_2"] = deck_id
        props.selectedAnswers[numTable + 1]["deck_1"] = deck_id
    };


    return (
        <Alert variant={checkedRezult && checkedDeck1 && checkedDeck2 ? 'success' : 'warning'} key={props.index}>
            <Form>
                <Row>
                    <Col><h4>Стол № {props.item.table}</h4></Col>
                </Row>
                <Row>
                    <Col sm={5}><h5>{props.item.player1}</h5></Col>
                    <Col sm={2}></Col>
                    <Col sm={5}><h5>{props.item.player2}</h5></Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <Form.Check
                            inline
                            label="Победа"
                            name={"group_" + props.item.table}
                            type="radio"
                            id={`inline-radio-1` + props.item.table}
                            onChange={() => {
                                handleOptionChange(props.index, 1, props.item, props.event_tour)
                                setCheckedRezult(true)
                            }}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            inline
                            label="Ничья"
                            name={"group_" + props.item.table}
                            type="radio"
                            id={`inline-radio-2` + props.item.table}
                            onChange={() => {
                                handleOptionChange(props.index, 2, props.item, props.event_tour)
                                setCheckedRezult(true)
                            }}
                        />
                    </Col>
                    <Col sm={5}>
                        <Form.Check
                            inline
                            label="Победа"
                            name={"group_" + props.item.table}
                            type="radio"
                            id={`inline-radio-3` + props.item.table}
                            onChange={() => {
                                handleOptionChange(props.index, 3, props.item, props.event_tour)
                                setCheckedRezult(true)
                            }}
                        />
                    </Col>
                </Row>
                
                <Row>
                    <Col sm={5}>
                    {}
                        {props.item.deckAdded_id_1.map((deck, index1) => (
                            <><Form.Check
                                inline
                                //checked ={item.deckAdded_id_2.length > 1 ? false : true}
                                // {selectedAnswers[item.table - 1] ? 'success' : 'warning'}
                                disabled={props.selectedAnswers[props.index] ? false : true}
                                label={deck[1]}
                                name={"group_" + props.item.table + "_" + props.item.player1_id}
                                type="radio"
                                id={`inline-radio-` + index1 + props.item.table + "_" + deck[0]}
                                onChange={() => {
                                    handleOptionDecksChange1(props.index, deck[0])
                                    setCheckedDeck1(true)
                                }}
                            /><br></br></>
                        ))}
                    </Col>
                    <Col></Col>
                    <Col sm={5}>
                        {props.item.deckAdded_id_2.map((deck, index1) => (
                            <><Form.Check
                                inline
                                //checked ={item.deckAdded_id_2.length > 1 ? false : true}
                                disabled={props.selectedAnswers[props.index] ? false : true}
                                label={deck[1]}
                                name={"group_" + props.item.table + "_" + props.item.player2_id}
                                type="radio"
                                id={`inline-radio-` + index1 + props.item.table + "_" + deck[0]}
                                onChange={() => {
                                    handleOptionDecksChange2(props.index, deck[0])
                                    setCheckedDeck2(true)
                                }}
                            /><br></br></>
                        ))}
                    </Col>
                </Row>
            </Form>
        </Alert>

    );
}

export default TableRezult;


/*
<Row>
                    <Col sm={5}>
                        <Form.Group controlId="formFile1" className="mb-3">
                            <Form.Label>Начальная раскладка на поле боя:</Form.Label>
                            <Form.Control type="file" size="sm" onChange={(event) => handleFileChange(event, props.index)} />
                        </Form.Group>
                    </Col>
                    <Col></Col>
                    <Col sm={5}>
                        <Form.Group controlId="formFile2" className="mb-3">
                            <Form.Label>Начальная раскладка на поле боя:</Form.Label>
                            <Form.Control type="file" size="sm" onChange={(event) => handleFileChange(event, props.index + 1)} />
                        </Form.Group>
                    </Col>
                </Row>
                */