import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import config_site from '../../config_site';

import {
    NavLink,
} from 'react-router-dom';
import { Breadcrumb, Col, Container, Row, Table } from 'react-bootstrap';
import Bars from "../Charts/Bars";

function CardsMonthStatistics(props) {
    const [data, setData] = useState([]);
    const [dataBars1, setBarsData1] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        fetch(`${config_site.apiBaseUrl}/api/CardsMonthStatistics/`)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);
                let rez_arr = []
                for (let i = 0; i < data_in.length; i++) {
                    let labels1 = []
                    let card_count1 = []
                    for (let ii = 0; ii < data_in[i].data.length; ii++) {
                        labels1.push(data_in[i].data[ii].card_name + " (" + data_in[i].data[ii].card_count + ")")
                        card_count1.push(data_in[i].data[ii].card_count)
                    }
                    let dataBarsBUF1 = {
                        labels: labels1,
                        datasets: [
                            {
                                label: 'Количество',
                                data: card_count1,
                                backgroundColor: 'rgba(' + Math.random() * 256 + ', ' + Math.random() * 256 + ', ' + Math.random() * 256 + ', 0.7)',
                            },
                        ],
                    };
                    rez_arr.push(dataBarsBUF1)
                }
                setBarsData1(rez_arr)
            });
        return () => {
            setData([]);
            setBarsData1({})
            setIsLoading(true);
        };
    }, []);

    return isLoading ? (
        <div>Loading...</div>
    ) : (
        <Container className="mt-3">
            <Breadcrumb className='mt-3'>
                <Breadcrumb.Item><NavLink to={'/'}>Главная</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item active>Статистика по картам</Breadcrumb.Item>
            </Breadcrumb>

            <Row><Col>На данной странице представлена статистика популярности карт в лимитед форматах за весь период сбора данных</Col></Row>
            <Row >
                <Col md={4}>
                    <Button className="mt-3" as={NavLink} to={"/statistics/month/"} variant="primary">Статистика по месяцам</Button>
                </Col>
                <Col md={4}>
                    <Button className="mt-3" as={NavLink} to={"/statistics/sets/"} variant="primary">Статистика по сетам</Button>
                </Col>
                <Col md={4}>
                    <Button className="mt-3" as={NavLink} variant="secondary">Настраиваемая статистика</Button>
                </Col>
            </Row>
            {data.map((item, id) => (
                <Row key={id}>
                    <Row className="mt-3"><Col><h4>{item.text}</h4></Col></Row>
                    <Col md={6} style={{ height: '23rem' }}><Bars data={dataBars1[id]}></Bars></Col>
                    {item.data.map((item_1, id) => (
                        <Col key={id} className="mt-3">
                            <div className="tooltiptooltip_s">
                                <Card style={{ width: '8.8rem' }}>
                                    <Card.Img variant="top" src={'http://localhost:3000/src/img/' + item_1.card_img} />
                                    <Card.Body>
                                        {item_1.card_name}<br />({item_1.card_count})
                                    </Card.Body>
                                </Card>
                                <span className="tooltiptexttooltiptext_s">
                                    <img src={"http://localhost:3000/src/img/" + item_1.card_img} width="300" height="450" style={{ borderRadius: 20 }} />
                                </span>
                            </div>



                        </Col>
                    ))
                    }
                </Row>
            ))}
        </Container>
    );
}
export default CardsMonthStatistics;


// <Col style={{ height: '30rem' }}><Bars data={dataBars1}></Bars></Col>