import './deck_add.css';
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import {
    NavLink,
} from 'react-router-dom';


function Deck_add(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams();
    const [textareaText, setTextareaText] = React.useState("");
    const [posts, setPosts] = React.useState([]);

    const addCardInDeckFromTA = () => {
        const newPost = {
            id: Math.random(),
            value: textareaText,
            timestamp: new Date(),
        };
        setPosts([...posts, newPost]);
        //setTextareaText("");
    };

    const checkCardInTA = () => {
        let dataCardList = textareaText.split('\n');

        for (let step = 0; step < dataCardList.length; step++) {
            dataCardList[step] = dataCardList[step].split(" х ")
        }
    };

    return isLoading ? (
        <div>Loading...</div>
    ) : (<div className="container">
        <br />
        <textarea
            className='deck-add_text-area'
            placeholder="Enter post your here ..."
            value={textareaText}
            onChange={(e) => setTextareaText(e.target.value)}
        />
        <br />
        <br />

        <button onClick={checkCardInTA}>Проверить</button>
        <button onClick={addCardInDeckFromTA}>Добавить</button>
        <br />
        <br />

        <label>Формат:</label>
        <br />
        <select name="favColor">
            <option value="blue">Стандарт</option>
            <option value="green">Драфт</option>
            <option value="red">Силед</option>
        </select>
    </div>
    );
}
export default Deck_add;
