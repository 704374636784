import './decks.css';
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config_site from '../../config_site';

import {
    NavLink,
} from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';

function Decks(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams();

    useEffect(() => {
        setIsLoading(true);
        fetch(`${config_site.apiBaseUrl}/api/variantlist/`)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);


            });
            return () => {
                setData([]);
                setIsLoading(true);
              };
    }, []);

    return isLoading ? (
        <div>Loading...</div>
    ) : (<Container>
        <div >
            <Row>
                {data.map(item => (
                    <Col className="mt-3">
                        <NavLink to={`/groupdecks/${item.variantName}`} exact="true" className="text-decoration-none text-reset" >
                            <Card style={{ width: '12rem', height: "18rem" }}>
                                <Card.Img  variant="top" src={item.variant_photo === null ? `${config_site.apiBaseUrl}/src/img/media/deck_img/deck404.png` : `${config_site.apiBaseUrl}` + item.variant_photo} />
                                <Card.Body>
                                    <Card.Title >{item.variantName}</Card.Title>
                                </Card.Body>
                            </Card>
                        </NavLink>
                    </Col>

                ))}


            </Row>
        </div>
    </Container>
    );
}
export default Decks;
