import './deck.css';
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config_site from '../../config_site'

import {
    NavLink,
} from 'react-router-dom';

function Set(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        fetch(`${config_site.apiBaseUrl}/api/deckView/?deck_id=` + params.id)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);
            });
            return () => {
                setData([]);
                setIsLoading(true);
              };
    }, []);

    return isLoading || (data === '[]') ? (
        <div>Loading...</div>
    ) : (<div className="container">
        <div className="deck">
            <div className='deck-name'>
                {data.name}
                <span className='author'>{data.sourse}</span>
            </div>
            <div className='deck-information'>
                Формат: {data.format}<br></br>
                Событие: {data.ivent}<br></br>
                Источник колоды: <a href='https://www.youtube.com/@nosytavern'>{data.sourse} </a> 
                <a href='https://www.youtube.com/watch?v=9TrZxwOVd-I'> (https://www.youtube.com/watch?v=9TrZxwOVd-I)</a><br></br>
                <br></br>   
                Дата добавления: {data.date_add}<br></br><br></br>
            </div>
            <div className="card-list">
                <table>
                    <tbody>
                        <tr>
                            <td>Количество</td>
                            <td>Имя карты</td>
                            <td>Цена</td>
                        </tr>
                        {

                            data.deckData.map(item => (
                                <tr>
                                    <td>{item.count}</td>
                                    <td><NavLink to={`/card_search/?q=${item.card_name}`} exact="true" className="deck-box">
                                        {item.card_name}
                                    </NavLink></td>
                                    <td></td>
                                </tr>




                            ))}
                    </tbody>
                </table>


            </div>
        </div>
    </div>
    );
}
export default Set;


