import React from 'react';
import { CompositeDecorator } from 'draft-js';
import MathJax from 'react-mathjax-preview'

// Стратегия поиска изображений
const findImageEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE'
      );
    },
    callback
  );
};

// Компонент для рендеринга изображений
const Image = (props) => {
  const { src, alt } = props.contentState.getEntity(props.entityKey).getData();
  return <img src={src} alt={alt} style={{ maxWidth: '100%' }} />;
};

// Стратегия поиска ссылок
const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
};

// Компонент для рендеринга ссылок
const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

// Стратегия поиска видео
const findVideoEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'VIDEO'
      );
    },
    callback
  );
};

// Компонент для рендеринга видео
const Video = (props) => {
  const { src } = props.contentState.getEntity(props.entityKey).getData();
  return <video controls src={src} style={{ width: '100%' }} />;
};

// Стратегия поиска аудио
const findAudioEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'AUDIO'
      );
    },
    callback
  );
};

const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const findMathEntities = (contentBlock, callback, contentState) => {
  findWithRegex(/\$\$[^\$]+\$\$/g, contentBlock, callback);
};

const MathComponent = (props) => {
  const math = props.decoratedText.slice(2, -2); // Remove the $$ delimiters
  return (
    <MathJax math={`\\(${math}\\)`} />
  );
};


// Компонент для рендеринга аудио
const Audio = (props) => {
  const { src } = props.contentState.getEntity(props.entityKey).getData();
  return <audio controls src={src} style={{ width: '100%' }} />;
};

// Создание CompositeDecorator с использованием стратегий и компонентов
const decorator = new CompositeDecorator([
  {
    strategy: findImageEntities,
    component: Image,
  },
  {
    strategy: findLinkEntities,
    component: Link,
  },
  {
    strategy: findVideoEntities,
    component: Video,
  },
  {
    strategy: findAudioEntities,
    component: Audio,
  },
  {
    strategy: findMathEntities,
    component: MathComponent,
  },
]);

export default decorator;
