import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import config_site from '../../config_site';

function AddPrizes(props) {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isLoading, setIsLoading] = useState(true);
    const [if_pr, setIf_pr] = useState(0);
    const params = useParams();

    let type_p = {
        0: "BOOSTER",
        1: "CARD",
        2: "MONEY",
    }

    function prizesif(code){
        if(code < 100){
            return "Топ-"+ (code+1)
        }
        if(code === 103){
            return "Не мене 3х игр в каждом подивенте"
        }
    }

    useEffect(() => {
        let config = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        }

        let url = `${config_site.apiBaseUrl}/api/PrizesTournametView/?tid=` + params.tid
        fetch(url, config).then(async response => {
            let data_in2 = await response.json();
            setData(data_in2);
            console.log("UsersInPlay")
            console.log(data_in2)
            console.log("UsersInPlay")

        });
        return () => {
        };
    }, [])

    const deletePr = (event, id) => {
        event.preventDefault()
        console.log("Удаляем " + id)
        let config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        }
        console.log(data)
        fetch(`${config_site.apiBaseUrl}/api/PrizesTournametView/?pid=` + id, config)
            .then(async response => {
                let data_in = await response.json();
                if (data_in.aaa === '200 ok!')
                    setIsLoading(true);
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;

        let data = {
            "event": params.tid,
            "prizes_text": form.prizes_text.value,
            "prizes_pretext": form.prizes_pretext.value,
            "prizes_type": type_p[Number(form.prizes_type.value)],
        }

        if (if_pr < 100)
            data["prizes_condition"] = Number(form.prizes_condition_2.value)-1
        if (if_pr === 103)
            data["prizes_condition"] = Number(form.prizes_condition.value)

        let config = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        }
        console.log(data)
        fetch(`${config_site.apiBaseUrl}/api/PrizesTournametView/`, config)
            .then(async response => {
                let data_in = await response.json();
                //setData(data_in);
                props.setCountTours(Math.ceil(Math.log2(data_in.data2.length)))
                props.addUser(data_in);
                if (data_in.aaa === '200 ok!')
                    setIsLoading(true);
            });
    };


    //
    return (
        <>Призы турнира
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Название приза</th>
                        <th>Количество<br/>(текст перед иконкой приза)</th>
                        <th>Условие</th>
                        <th>DEL</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id}>
                            <th>{item.prizes_text}</th>
                            <th>{item.prizes_pretext}</th>
                            <th>{prizesif(item.prizes_condition)}</th>
                            <th><button onClick={(event)=>(deletePr(event, item.id))}>Х</button></th>
                        </tr>
                    ))}

                </tbody>
            </Table>
            <Button variant="primary" onClick={handleShow}>
                Добавить приз
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Добавление приза</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Название приза:</Form.Label>
                            <Form.Control
                                id="prizes_text"
                                type="text"
                                placeholder="50% бустеров призового фонда"
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Количество (текст перед иконкой приза):</Form.Label>
                            <Form.Control
                                id="prizes_pretext"
                                type="text"
                                placeholder="50%"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Тип приза:</Form.Label>
                            <Form.Select id="prizes_type" aria-label="Default select example" >
                                <option value="0">Бустер(-ы/-а)</option>
                                <option value="1">Карта(-ы)</option>
                                <option value="2">Золото</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Условие получения:</Form.Label>
                            <div style={{ display: 'flex' }}>
                                <Form.Select id="prizes_condition" aria-label="Default select example" onChange={(event)=>(setIf_pr(Number(event.target.value)))}>
                                <option value="0">Топ-</option>
                                <option value="103" disabled={false}>Не менее 3 игр в подивентах</option>
                            </Form.Select>
                            {if_pr == 0 ? <Form.Control type="number" id="prizes_condition_2" defaultValue={1}/> : <></>}
                            </div>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Закрыть
                        </Button>
                        <Button variant="primary" type='submit'>
                            Добавить
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default AddPrizes;