import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import config_site from '../../config_site';

function AddUserModal(props) {
    const [show, setShow] = useState(false);
    const [participants, setParticipants] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const params = useParams();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleClose = () => {
        setShow(false);
        setParticipants('');
        setErrors([]);
    };

    const handleShow = () => setShow(true);

    const validateParticipant = (participant) => {
        const regex = /^[a-zA-Zа-яёА-ЯЁ0-9_ ]+$/;

        if (!participant.trim()) {
            return 'Имя участника не может быть пустым.';
        } else if (!regex.test(participant)) {
            return 'Имя участника может содержать только цифры, буквы английского и русского языков, подчеркивания. ';
        }

        return '';
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!isButtonDisabled) {
            setIsButtonDisabled(true);
        }
        const participantsArray = participants.split('\n').map(participant => participant.trim()).filter(participant => participant);

        const errors = participantsArray.map((participant, index) => ({
            index: index,
            error: validateParticipant(participant)
        })).filter(error => error.error);

        if (errors.length > 0) {
            setErrors(errors);
            setIsButtonDisabled(false);
            return;
        }

        let data = participantsArray.map(participant => ({
            "event": params.tid,
            "is_registered": true,
            "first_name": participant,
            "last_name": '',
        }));

        let config = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        }

        fetch(`${config_site.apiBaseUrl}/api/addUserInTournament/?t_id=` + params.tid, config)
            .then(async response => {
                let data_in = await response.json();
                props.setCountTours(Math.ceil(Math.log2(data_in.data2.length)));
                props.addUser(data_in);
                if (data_in.aaa === '200 ok!') {
                    setIsLoading(false);
                }
                setIsButtonDisabled(false);
                handleClose(); // Закрываем и сбрасываем состояние после успешного добавления
            });
    };

    const handleShuffle = () => {
        const shuffledParticipants = participants.split('\n').map(participant => participant.trim()).filter(participant => participant);
        shuffledParticipants.sort(() => Math.random() - 0.5);
        setParticipants(shuffledParticipants.join('\n'));
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Добавить участников
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Добавление участников</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Имена участников (каждое с новой строки):</Form.Label>
                            <Form.Control
                                id="participants"
                                as="textarea"
                                rows={5}
                                placeholder="Введите имена участников..."
                                value={participants}
                                onChange={(e) => setParticipants(e.target.value)}
                                autoFocus
                            />
                            {errors.map(error => (
                                <Form.Text key={error.index} className="text-danger">
                                    Строка {error.index + 1}: {error.error}
                                </Form.Text>
                            ))}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Закрыть
                        </Button>
                        <Button variant="primary" onClick={handleShuffle}>
                            Перемешать
                        </Button>
                        <Button variant="primary" type='submit' disabled={isButtonDisabled}>
                            {isButtonDisabled ? 'Подождите...' : 'Добавить'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default AddUserModal;
