import './deck.css';
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import config_site from '../../config_site';

import {
    NavLink,
} from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

function Sets(props) {
    const [data, setData] = useState("[]");
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        fetch(`${config_site.apiBaseUrl}/api/SetsView/`)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);
            });
            return () => {
                setData([]);
                setIsLoading(true);
              };
    }, []);

    return isLoading || (data === "[]") ? (
        <div>Loading...</div>
    ) : (<Container>
        <Row>
        {data.map(item => (
            <Card className="mb-2 mt-2">
                <Row >
                    <Col md={4}>
                        <Card.Img variant="top" src={item.deck_img} className="mb-3 mt-3" />
                    </Col>
                    <Col>
                        <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <Card.Text>
                                {item.description}
                            </Card.Text>
                            <Button  as={NavLink} to={"/cards/" + item.id + "/"}  variant="primary">Смотреть карты</Button>
                        </Card.Body>
                    </Col>
                </Row>


            </Card>
        ))}


</Row>
    </Container>
    );
}
export default Sets;


