import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { signup } from '../actions/auth';

const Signup = ({ signup, isAuthenticated }) => {
    const [accountCreated, setAccountCreated] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        email: '',
        password: '',
        re_password: ''
    });
    const [errors, setErrors] = useState({});

    const { first_name, email, password, re_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();

        if (password === re_password) {
            const errorResponse = await signup(first_name, email, password, re_password);

            if (errorResponse) {
                setErrors(errorResponse);
            } else {
                setAccountCreated(true);
            }
        } else {
            setErrors({ re_password: ["Passwords do not match"] });
        }
    };

    if (isAuthenticated) {
        return <Navigate to="/" />
    }
    if (accountCreated) {
        return <Navigate to="/login/" />
    }

    return (
        <div className='container mt-5'>
            <h1>Зарегистрироваться</h1>
            <p>Создать аккаунт</p>
            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='text'
                        placeholder='Отображаемое имя*'
                        name='first_name'
                        value={first_name}
                        onChange={e => onChange(e)}
                        required
                    />
                    {errors.first_name && <div className='alert alert-danger'>{errors.first_name.join(', ')}</div>}
                </div>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='email'
                        placeholder='Email*'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                    />
                    {errors.email && <div className='alert alert-danger'>{errors.email.join(', ')}</div>}
                </div>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='Пароль*'
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                    {errors.password && <div className='alert alert-danger'>{errors.password.join(', ')}</div>}
                </div>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='Повторите пароль*'
                        name='re_password'
                        value={re_password}
                        onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                    {errors.re_password && <div className='alert alert-danger'>{errors.re_password.join(', ')}</div>}
                </div>
                <button className='btn btn-primary mt-3' type='submit'>Зарегистрироваться</button>
            </form>
            
            <p className='mt-3'>
            У Вас уже есть аккаунт? <Link to='/login'>Войти</Link>
            </p>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { signup })(Signup);
