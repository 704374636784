import { useParams, NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config_site from '../../config_site';
import '../Deck/deck.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Table from "react-bootstrap/esm/Table";
import { Alert, Button } from "react-bootstrap";
import { connect } from "react-redux";
import EditorText from "../Editor/EditorText";
import { EditorState, convertFromRaw } from "draft-js";
import decorator from "../Editor/decorators";
import TiebreakView from "./TiebreakView";

function StandingsView(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tour, setTour] = useState(props.tour_pl);
    const params = useParams();



    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(`${config_site.apiBaseUrl}/api/RezultTournametView/?t_id=${params.id}&t_r=${tour}`);
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error);
                }
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(true)

            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            setData([]);
            setIsLoading(false);
        };
    }, [tour]);

    return (
        <>
            <Row>
                <Col>
                    <div style={{ display: 'flex' }}>
                        {props.tour ? [...Array(props.tour_pl).keys()].map((index) => (
                            <Button className="m-1"
                                onClick={(e) => (setTour(index + 1))}
                                variant={props.tour < index + 1 ?
                                    tour === (index + 1) ?
                                        "primary" : "outline-primary" :
                                    tour === (index + 1) ?
                                        "success" : "outline-success"}
                                size="lg">Тур № {index + 1}</Button>
                        )) : <></>}
                    </div>
                </Col>
            </Row>


            {isLoading ? <>{data.event_type === 2 && <h2>Текущий рейтинг</h2>}
                <Table className="mt-3">
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Участник</th>
                            <th>Очки</th>
                            <th>Результат<br></br>П (АП)-Н-Пр</th>
                            {(data.event_koef <= 1 && data.event_type <= 1) ?
                                <><th>% выйгранных<br></br>матчей оппонентов</th>
                                    <th>% побед</th>
                                    <th>% выйгранных<br></br>игр оппонентов</th>
                                </> : (data.event_koef === 2 && data.event_type <= 1) ?
                                    <><th>Коэффициент<br></br>Бухгольца</th>
                                    </> : ""}
                            {data.event_type === 2 || data.event_type === 3 ? (
                                <th>
                                    {data.event_koef === 0 && <><br />Количество игр</>}
                                    {data.event_koef === 1 && <>Количество игр<br />(Топ-1 / Топ-2)</>}
                                    {data.event_koef === 2 && <>Призы</>}
                                </th>
                            ) : (
                                <th>Колода</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.data.map((item, index) => (
                            <tr key={index}>
                                {data.event_raspred === 1 ? (
                                    <th>Топ-{Math.pow(2, Math.ceil(Math.log2(index + 1)))}</th>
                                ) : (
                                    <th>{index + 1}</th>
                                )}
                                <th>{item.user > 0 ? <NavLink to={`/profile/${item.user}`}>{item.is_in_play ? item.first_name : <s>{item.first_name}</s>}</NavLink> : item.is_in_play ? item.first_name : <s>{item.first_name}</s>}</th>
                                <th>{item.rating}</th>
                                <th>{item.battle_result_w + item.battle_aw_ball} ({item.battle_aw_ball})-{item.battle_result_n}-{item.battle_result_l}</th>
                                {(data.event_koef <= 1 && data.event_type <= 1) ?
                                    <>
                                        <th><TiebreakView data={item.p1} tipe={1} /></th>
                                        <th><TiebreakView data={item.p2} tipe={2} /></th>
                                        <th><TiebreakView data={item.p3} tipe={1} /></th>
                                    </> : (data.event_koef === 2 && data.event_type <= 1) ?
                                        <>
                                            <th><TiebreakView data={item.p1} tipe={3} /></th>
                                        </> : ''}
                                {data.event_type === 2 || data.event_type === 3 ? (
                                    <th>
                                        {data.event_koef === 0 && <>{item.count_game}</>}
                                        {data.event_koef === 1 && <>{item.count_game} ({item.top1} / {item.top2})</>}
                                        {data.event_koef === 2 && item.prizes?item.prizes.map((prize, index) => (
                                            <React.Fragment key={index}>
                                                {index > 0 && " + "}
                                                {prize.pre_txt && `${prize.pre_txt} Х `}
                                                <img
                                                    src={`https://test.berserkmeta.ru/src/img/media/${prize.img_url}`}
                                                    alt={prize.img_txt}
                                                    width={prize.img_w}
                                                    height={prize.img_h}
                                                    title={prize.img_txt}
                                                />
                                            </React.Fragment>
                                        )):<></>}
                                    </th>
                                ) : (
                                    <th>
                                        {item.deckAdded_id.map((deck, index) => (
                                            <div key={index}><NavLink to={`/decks/${deck[0]}`}>{deck[1]}</NavLink></div>
                                        ))}
                                    </th>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>


                {(data.event_type === 2 || data.event_type === 3) && (
                    <Row>
                        <Col>
                            <h3>Предстоящие события</h3>
                            <Row>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Дата</th>
                                            <th>Название</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.coming.map((item, index) => (
                                            <tr key={index}>
                                                <th>{item.event_date.split('T')[0]}</th>
                                                <th><NavLink to={`/tournaments/view/${item.id}`}>{item.event_name}</NavLink></th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Row>
                        </Col>
                        <Col sm={1}></Col>
                        <Col>
                            <h3>Завершившиеся события</h3>
                            <Row>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Дата</th>
                                            <th>Название</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.past.map((item, index) => (
                                            <tr key={index}>
                                                <th>{item.event_date.split('T')[0]}</th>
                                                <th><NavLink to={`/tournaments/view/${item.id}`} onClick={() => setIsLoading(true)}>{item.event_name}</NavLink></th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Row>
                        </Col>
                    </Row>
                )}</> : <></>}
        </>
    );
}

export default StandingsView;
