import Container from 'react-bootstrap/Container';
import { Row } from 'react-bootstrap';

let expand='md'

function Page404(props) {
    return (<> 
        <Container>
          <Row>
            <img  width={300} height={500} src='/src/img/page404img.png'></img>
            <div>
              Тут что-то было.... И... Оно потерялось...
            </div>
            <div>
              Но мы это обязательно найдем!!!
            </div>
          </Row>
        </Container>
    </>)
  }

export default  Page404;