import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config_site from '../../config_site';
import './UserProfile.css';

const UserProfileEdit = ({ userId, onCancel, onSave }) => {
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        axios.get(`${config_site.apiBaseUrl}/api/users/${userId}/`)
            .then(response => setUser(response.data))
            .catch(error => console.error('Error fetching user:', error));

        axios.get(`${config_site.apiBaseUrl}/api/profiles/${userId}/`)
            .then(response => setProfile(response.data))
            .catch(error => console.error('Error fetching profile:', error));
    }, [userId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: value
        }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        axios.put(`${config_site.apiBaseUrl}/api/profiles/${profile.id}/`, profile)
            .then(response => {
                onSave();
            })
            .catch(error => console.error('Error updating profile:', error));
    };

    if (!user || !profile) return <div>Loading...</div>;

    return (
        <div className="user-profile-edit">
            <h1>Edit Profile of {user.first_name} {user.last_name}</h1>
            <form onSubmit={handleFormSubmit}>
                <label>
                    Display Name:
                    <input
                        type="text"
                        name="display_name"
                        value={profile.display_name}
                        onChange={handleInputChange}
                    />
                </label>
                <button type="submit">Save</button>
                <button type="button" onClick={onCancel}>Cancel</button>
            </form>
        </div>
    );
};

export default UserProfileEdit;
