import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config_site from '../../config_site';
import './UserProfile.css';
import { Col, Row, Container, Card, Button, Table } from 'react-bootstrap';
import {
    NavLink,
} from 'react-router-dom';
import RedDeck from '../Tournaments/RedDeck';

const UserDecksView = ({ userId, onEdit }) => {
    const [userDecks, setUserDecks] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('access');
        axios.get(`${config_site.apiBaseUrl}/api/GetDecksUser/`, {
            headers: { Authorization: `JWT ${token}` }
        })
            .then(response => {
                setUserDecks(response.data)
                setIsLoading(true)
            })
            .catch(error => console.error('Error fetching user:', error));
    }, [isLoading]);

    if (!userDecks) return <div>Loading...</div>;

    return (
        <Container>
            <Row className='mt-3'>
                <Col sm={2}></Col>
                <Col sm={8}>
                    Колоды
                    <Table>
                        <thead>
                            <tr>
                                <th>Название Турнира</th>
                                <th>Название колоды</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userDecks ?
                                userDecks.map((item, index) => (
                                    <tr key={index}>
                                        <th>{item.event_name}</th>
                                        <th>{item.name}</th>
                                        <th><RedDeck key={index} f={setIsLoading}
                                            deckdata={[item.id, item.name, item.is_autoAdd]} /></th>

                                    </tr>
                                ))
                                : <></>}
                        </tbody>
                    </Table>
                </Col>
                <Col sm={2}></Col>
            </Row>
        </Container>
    );
};

export default UserDecksView;
