import './decks.css';
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config_site from '../../config_site';

import {
    NavLink,
} from 'react-router-dom';
import { Row, Table } from 'react-bootstrap';

function Decksgroup(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams();

    useEffect(() => {
        setIsLoading(true);
        fetch(`${config_site.apiBaseUrl}/api/deckView/?variant_2=` + params.group)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);


            });
            return () => {
                setData([]);
                setIsLoading(true);
              };
    }, []);

    return isLoading ? (
        <div>Loading...</div>
    ) : (<div className="container mt-3">
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Шазвание колоды</th>
                    <th>Игр отыграно</th>
                    <th>Винрейт</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, id) => (
                    <tr>
                        <td>{id + 1}</td>
                        <td>
                            <NavLink to={`/decks/${item.id}`} exact="true">
                                {item.name}
                            </NavLink>
                        </td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                ))}


            </tbody>
        </Table>
    </div>
    );
}
export default Decksgroup;
