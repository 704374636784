import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import config_site from '../../config_site';

import {
    NavLink,
} from 'react-router-dom';
import { Breadcrumb, Col, Container, Row, Table } from 'react-bootstrap';
import Bars from "../Charts/Bars";

function DecksStatistics(props) {
    const [data, setData] = useState([]);
    const [dataBars, setBarsData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        fetch(`${config_site.apiBaseUrl}/api/DecksStatistics/`)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);

                let labels = []
                let deck_count = []
                let max_col = 40
                if ((max_col > data_in.length) && (data_in.length > 1)) {
                    max_col = data_in.length - 1
                }
                for (let i = 0; i < data_in.length; i++) {
                    if (i < max_col) {
                        labels.push(data_in[i].deck_type)
                        deck_count.push(data_in[i].deck_count)
                    }
                    else {
                        if (i < max_col) {
                            labels.push('Другие')
                            deck_count.push(data_in[i].deck_count)
                        }
                        else {
                            deck_count[max_col] += data_in[i].deck_count
                        }
                    }
                }


                let dataBarsBUF = {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Количество колод',
                            data: deck_count,
                            backgroundColor: 'rgba(' + Math.random() * 256 + ', ' + Math.random() * 256 + ', ' + Math.random() * 256 + ', 0.7)',
                        },
                    ],
                };


                setBarsData(dataBarsBUF)
            });
            return () => {
                setData([]);
                setIsLoading(true);
                setBarsData({})
              };
    }, []);

    return isLoading || (data === []) ? (
        <div>Loading...</div>
    ) : (
        <Container className="mt-3">
            <Breadcrumb className='mt-3'>
                <Breadcrumb.Item><NavLink to={'/'}>Главная</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item active>Статистика по колодам</Breadcrumb.Item>
            </Breadcrumb>
            Статистика количества колод формата "Стандарт" по стихиям в базе данных
            <Row style={{ height: '22rem' }}><Bars data={dataBars}></Bars></Row>

            {
                data.map((item, id) => (
                    <Button as={NavLink} to={`/groupdecks/${item.deck_type}`} exact="true" variant="outline-dark" className="m-2" style={{ width: '18rem' }}>
                        {item.deck_type} ({item.deck_count})
                    </Button>
                ))
            }

        </Container>
    );
}
export default DecksStatistics;


