import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import config_site from '../../config_site';

function RedDeck(props) {
    const [data, setData] = useState("[]");
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();
    const [show, setShow] = useState(false);

    const [deckList, setDeckList] = useState("");
    const [photoDeck, setPhotoDeck] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [serverErrors, setServerErrors] = useState([]);

    const [isNumberFormat, setIsNumberFormat] = useState(true);
    const [isNewDeckName, setIsNewDeckName] = useState(false);
    const [isSwitchDisable, setISSwitchDisable] = useState(false);
    const [duplicateCards, setDuplicateCards] = useState([]);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [cardsToDelete, setCardsToDelete] = useState([]);

    const handleClose = () => {
        setDeckList("")
        setIsLoading(true)
        setIsNumberFormat(true)
        setISSwitchDisable(false)
        setShow(false)

    setData("[]");
    setIsLoading(true);
    setShow(false);

    setDeckList("");
    setPhotoDeck([]);
    setValidationErrors([]);
    setServerErrors([]);

    setIsNumberFormat(true);
    setIsNewDeckName(false);
    setISSwitchDisable(false);
    setDuplicateCards([]);
    setShowDuplicateModal(false);
    setCardsToDelete([]);
    };

    const handleShow = () => {
        fetch(`${config_site.apiBaseUrl}/api/RedDeck/?d_id=` + props.deckdata[0])
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                setIsLoading(false);
                setDeckList(data_in.card_in_deck)
            });

        setShow(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (isSwitchDisable) {
            let deck = deckList.split('\n');
            let isValid = true;
            let errors = [];
            let duplicates = {};

            const format1 = /^\d+ \D+$/;
            const format2 = /^\D{2} \d+ \d+$/;

            // Checking for duplicates
            /*deck.forEach((item, index) => {
                let cardName = item.split(' ').slice(0, -1).join(' ');
                if (duplicates[cardName]) {
                    duplicates[cardName].count++;
                    duplicates[cardName].indices.push(index);
                } else {
                    duplicates[cardName] = { count: 1, indices: [index] };
                }
            });*/

            let duplicateEntries = Object.entries(duplicates).filter(([key, value]) => value.count > 1);
            if (duplicateEntries.length > 0) {
                setDuplicateCards(duplicateEntries);
                setShowDuplicateModal(true);
                console.log('Выход 1')
                return;
            }

            for (let i = 0; i < deck.length; i++) {
                if (!format1.test(deck[i]) && !format2.test(deck[i])) {
                    isValid = false;
                    errors.push(deck[i]);
                }
            }

            setValidationErrors(errors);

            if (!isValid) {
                console.log('Выход 2')
                return;
            }
        }

        const formData = new FormData();

        if (isNewDeckName && isSwitchDisable) {
            formData.append('name', form.deck_name.value);
            formData.append('deckList', deckList)
        }
        else if (isSwitchDisable) {
            formData.append('name', data.deck_name);
            formData.append('deckList', deckList)
        }
        else if (isNewDeckName) {
            formData.append('name', form.deck_name.value);
            formData.append('deckList', '')
        }

        if (photoDeck !== "[]")
            formData.append('deck_img', photoDeck);

        let url = `${config_site.apiBaseUrl}/api/adddeletedeck/?cmd=upd&i=` + props.deckdata[0];

        const res = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        });

        const datadata = await res.json();
        if (res.status === 400 && datadata.missing_cards) {
            setServerErrors(datadata.missing_cards);
        } else {
            setServerErrors([]);
            props.f(false);
            handleClose()
        }
    };

    const handleDuplicateAction = (action) => {
        let updatedDeckList = deckList.split('\n');
        switch (action) {
            case 'sum':
                duplicateCards.forEach(([cardName, { indices }]) => {
                    let totalCards = 0;
                    indices.forEach(index => {
                        let cardDetails = updatedDeckList[index].split(' ');
                        let count = parseInt(cardDetails[cardDetails.length - 1], 10);
                        totalCards += count;
                    });

                    let baseCard = updatedDeckList[indices[0]].split(' ');
                    baseCard[baseCard.length - 1] = totalCards.toString();
                    updatedDeckList[indices[0]] = baseCard.join(' ');
                    indices.slice(1).forEach(index => updatedDeckList[index] = '');
                });
                break;
            case 'delete':
                updatedDeckList = updatedDeckList.filter((_, index) => !cardsToDelete.includes(index.toString()));
                break;
            default:
                break;
        }

        updatedDeckList = updatedDeckList.filter(item => item).join('\n');
        setDeckList(updatedDeckList);  // Обновляем состояние deckList
        setShowDuplicateModal(false);
        setDuplicateCards([]);
        setCardsToDelete([]);
    };

    const handleChangeDeckList = (event) => {
        if ((event.target.value === data.card_in_deck.text.replace(/\r/g, '')) || (event.target.value === data.card_in_deck.numb.replace(/\r/g, ''))) {
            setDeckList(data.card_in_deck)
            setISSwitchDisable(false)
        }
        else {
            setDeckList(event.target.value);
            setISSwitchDisable(true)
        }
    }

    const handleChangePhotoDeck = (event) => {
        setPhotoDeck(event.target.files[0]);
    }

    const handleChangeDeckName = (event) => {
        console.log(event.target.value)
        console.log(data.deck_name)
        console.log(event.target.value === data.deck_name)
        console.log(isNewDeckName)
        if (event.target.value === data.deck_name) {
            setIsNewDeckName(false)
        }
        else {
            setIsNewDeckName(true)
        }
    }



    const handleToggleSwitch = () => {
        //const isNumberFormat = validationErrors.length === 0 && deckList.split('\n').every(line => /^\d+ \D+$/.test(line) || /^\D{2} \d+ \d+$/.test(line));
        //if (isNumberFormat) {
        //    ;
        //}
        //let flag = 1
        setIsNumberFormat(!isNumberFormat)
        //setIsNumberFormat(flag)
    };

    const handleCardToDeleteChange = (index) => {
        if (cardsToDelete.includes(index.toString())) {
            setCardsToDelete(cardsToDelete.filter(item => item !== index.toString()));
        } else {
            setCardsToDelete([...cardsToDelete, index.toString()]);
        }
    };

    return (
        <>
            <Button variant={props.deckdata[2] ? "warning" : "success"} className="m-1" size="sm" onClick={handleShow}>
                Редактировать колоду
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Изменение колоды</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{isLoading ? (
                        <div>Loading...</div>
                    ) : (<>
                        <Form.Group className="mb-3">
                            <Form.Label>Название колоды</Form.Label>
                            <Form.Control
                                id="deck_name"
                                type="text"
                                placeholder='"Степи-Горы-Леса-Болота-Тьма" или "Очи Кронга"'
                                autoFocus
                                onChange={handleChangeDeckName}
                                defaultValue={data.deck_name}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ display: 'flex' }}>
                                <>Названия карт&nbsp;&nbsp;</>
                                <Form.Check
                                    type="switch"
                                    id="switch"
                                    //label={isNumberFormat ? "Номера карт" : "Названия карт"}
                                    checked={isNumberFormat}
                                    onChange={handleToggleSwitch}
                                    disabled={isSwitchDisable}
                                />
                                <>Номера карт</>
                            </Form.Label>

                            <Form.Control as="textarea" rows={5} id="event_text1"
                                placeholder=' 3 Очи кронга
                                    2 Крыселап
                                    или
                                    ВС 123 3
                                    НТ 17 2'
                                onChange={handleChangeDeckList}
                                value={isSwitchDisable ? deckList : isNumberFormat ? deckList.numb : deckList.text} // <- это обновление для отображения изменений
                            //defaultValue={data.card_in_deck}
                            />

                        </Form.Group></>)}
                        {validationErrors.length > 0 && (
                            <div style={{ color: 'red' }}>
                                <p>Неправильные записи:</p>
                                <ul>
                                    {validationErrors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {serverErrors.length > 0 && (
                            <div style={{ color: 'red' }}>
                                <p>Ненайденные карты:</p>
                                <ul>
                                    {serverErrors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <Modal show={showDuplicateModal} onHide={() => setShowDuplicateModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Дублирующиеся карты</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Обнаружены дублирующиеся карты:</p>
                                <Form.Group>
                                    {duplicateCards.map(([cardName, { indices }], index) => (
                                        <div key={index}>
                                            {indices.map((idx) => (
                                                <Form.Check
                                                    key={idx}
                                                    type="checkbox"
                                                    label={`${deckList.split('\n')[idx]}`}
                                                    checked={cardsToDelete.includes(idx.toString())}
                                                    onChange={() => handleCardToDeleteChange(idx.toString())}
                                                />
                                            ))}
                                        </div>
                                    ))}
                                </Form.Group>
                                <p>Что вы хотите сделать с дублирующимися картами?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" disabled onClick={() => handleDuplicateAction('sum')}>Суммировать</Button>
                                <Button variant="danger" disabled onClick={() => handleDuplicateAction('delete')}>Удалить выбранные</Button>
                                <Button variant="secondary" onClick={() => setShowDuplicateModal(false)}>Отмена</Button>
                            </Modal.Footer>
                        </Modal>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Закрыть
                        </Button>
                        <Button variant="primary" type='submit' disabled={!(isSwitchDisable || isNewDeckName)}>
                            Обновить
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default RedDeck;


/**
 * Более подробно: <a href='' target='_blank'>ТЫК</a><br /><br />
                        <Form.Group className="mb-3">
                            <Form.Label>Если нет возможности указать список карт, можно приложить фото колоды:</Form.Label>
                            <Form.Control type="file" size="sm" id="event_text2" onChange={handleChangePhotoDeck} />
                        </Form.Group>
 */