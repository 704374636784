import Container from "react-bootstrap/esm/Container";
import { NavLink, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TournamentsRunTableRezult from "./TournamentsRunTableRezult";
import TournamentsRunTable from "./TournamentsRunTable";
import TournamentsRunRezultAndAddDecks from "./TournamentsRunRezultAndAddDecks";
import TournamentsRunUserOut from "./TournamentsRunUserOut";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import config_site from '../../config_site';

function TournamentsRun({ is_club_owner, isAuthenticated }) {
    const [data, setData] = useState([]);
    const [isUserOut, setIsUserOut] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [error, setError] = useState(null);
    const params = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let config = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `JWT ${localStorage.getItem('access')}`,
                        'Accept': 'application/json'
                    },
                };

                let url = `${config_site.apiBaseUrl}/api/TournamentStatus/?t_id=${params.tid}`;
                let response = await fetch(url, config);

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error);
                }

                let data_in2 = await response.json();
                setData(data_in2);
                setisLoading(false);
            } catch (err) {
                setError(err.message); // Correctly setting the error message
                setisLoading(false); // Stop loading if there is an error
            }
        };

        fetchData();
    }, [params.tid, isLoading]);

    return isLoading ? (
        <div>Loading...</div>
    ) : error ? (
        <Alert variant="danger">{error}</Alert>
    ) : (
        <Container>
            {is_club_owner && isAuthenticated ? (
                <>
                    <TournamentsRunRezultAndAddDecks setisLoading={setisLoading} />
                    {data.is_tour_started ? (
                        <TournamentsRunTableRezult setisLoading={setisLoading} setIsUserOut={setIsUserOut} />
                    ) : data.is_completed ? (
                        <>
                            <h4>Турнир завершен!!!</h4>
                            <br>
                            </br>
                            <NavLink to={'/tournaments/view/' + params.tid}>Просмотреть</NavLink>
                        </>
                    ) : isUserOut ? (
                        <TournamentsRunTable setisLoading={setisLoading} setIsUserOut={setIsUserOut} />
                    ) : (
                        <TournamentsRunUserOut setIsUserOut={setIsUserOut} />
                    )}
                </>
            ) : (
                <h1>А Вам точно можно тут находиться?</h1>
            )}
        </Container>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    is_club_owner: state.auth.is_club_owner
});

export default connect(mapStateToProps, null)(TournamentsRun);
