import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from 'react';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Button, Alert, Table } from "react-bootstrap";
import { connect } from "react-redux";
import config_site from '../../config_site';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

function TournamentsUserReg({ isAuthenticated }) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [userTournamentsReg, setUserTournamentsReg] = useState(null);

    useEffect(() => {
        const config = {
            headers: {
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            },
        };

        fetch(`${config_site.apiBaseUrl}/api/TournamentsUserReg/`, config)
            .then(async response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data_in = await response.json();
                setUserTournamentsReg(data_in);

            })
            .catch(error => {
                console.error('There was a problem with fetching data:', error);

            });
    }, [success]);




    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsButtonDisabled(true);
        setError(null);
        setSuccess(null)

        const form = event.currentTarget;
        const data = {
            "event_code": form.event_code.value,
        };

        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        };

        try {
            const response = await fetch(`${config_site.apiBaseUrl}/api/TournamentsUserReg/`, config);
            const data_in = await response.json();

            if (!response.ok) {
                throw new Error(data_in.error || 'Something went wrong');
            }
            setSuccess(data_in.success)
        } catch (err) {
            setError(err.message);
        } finally {
            setIsButtonDisabled(false);
        }
    };

    return (
        <Container>
            <Row>
                <Col sm={4}></Col>
                <Col sm={4}>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <Form className="mb-3" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Введите код турнира</Form.Label>
                            <Form.Control id="event_code" type="text" />
                        </Form.Group>
                        <Button type="submit" disabled={isButtonDisabled} variant="outline-success">
                            {isButtonDisabled ? 'Подождите...' : 'Зарегистрироваться'}
                        </Button>
                    </Form>
                </Col>
                <Col sm={4}></Col>
            </Row>
            <Row>
<Col>Список турниров, куда отправлен запрос на участие</Col>

                <Table>
                    <thead>
                        <tr>
                        <th>№</th>
                        <th>Турнир</th>
                            <th>Дата</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userTournamentsReg ? userTournamentsReg.map((item, index) => (
                            <tr key={index}>
                                <th>{index+1}</th>
                                <th><NavLink to={`/tournaments/view/${item.id}`}>{item.event_name}</NavLink></th>
                                <th>{item.event_date.split('T')[0]}</th>

                            </tr>
                        )) : <></>}

                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, null)(TournamentsUserReg);
