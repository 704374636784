
import 'bootstrap/dist/css/bootstrap.min.css';
import 'contenido/dist/styles.css';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { connect } from 'react-redux';


import NavbarD from './Components/Navbar/NavbarD';
import Home from './Components/Home/Home';
import Decks from './Components/Decks/Decks';
import Deck from './Components/Deck/Deck';
import Deck_add from './Components/Deck_add/Deck_add';
import Sets from './Components/Sets/Sets';
import Set from './Components/Set/Set';
import Login from './containers/Login';

import TournamentsPage from './Components/Tournaments/TournamentsPage';
import Signup from './containers/Signup';
import TournamentsCreate from './Components/Tournaments/TournamentsCreate';
import TournamentsClub from './Components/Tournaments/TournamentsClub';
import TournamentsDetails from './Components/Tournaments/TournamentsDetails';
import TournamentsRun from './Components/Tournaments/TournamentsRun';
import Cards from './Components/Cards/Cards';
import Decksgroup from './Components/Decksgroup/Decksgroup';
import ClubsStatistics from './Components/Statistics/ClubsStatistics';
import DecksStatistics from './Components/Statistics/DecksStatistics';
import CardsMonthStatistics from './Components/Statistics/CardsMonthStatistics';
import MonthStatistics from './Components/Statistics/MonthStatistics';
import AllMonthStatistics from './Components/Statistics/AllMonthStatistics';
import Logintest from './Logintest';
import TournamentsView from './Components/TournamentsView/TournamentsView';
import ClubView from './Components/Clubs/ClubView';
import { useEffect } from 'react';
import { load_user, checkAuthenticated, refreshAccess } from './actions/auth';
import MyProfile from './Components/UserProfile/MyProfile';
import ProfileView from './Components/UserProfile/ProfileView';
import TournamentsUserReg from './Components/Tournaments/TournamentsUserReg';
import UserDecksView from './Components/UserProfile/UserDecksView';
import AllSetsStatistics from './Components/Statistics/AllSetsStatistics';
import CardsSetStatistics from './Components/Statistics/CardsSetStatistics';

const usePageTitle = (titles) => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    let title = 'BerserkMeta';

    for (const key in titles) {
      const regex = new RegExp(`^${key.replace(/:[^\s/]+/g, '[^/]+')}$`);
      if (regex.test(path)) {
        title = titles[key];
        break;
      }
    }

    document.title = title;
  }, [location, titles]);
};

function App({ load_user, checkAuthenticated, isAuthenticated, refreshAccess }) {

  const titles = {
    '/': 'Главная | БерсеркМета',
    '/sets': 'Выпуски | БерсеркМета',
    '/sets/': 'Выпуски | БерсеркМета',
    '/tournaments': 'Турниры | БерсеркМета',
    '/tournaments/': 'Турниры | БерсеркМета',
    '/tournaments/view/:id': 'Турниры | БерсеркМета',
  };

  useEffect(() => {
    console.log("Проверка токена")
    checkAuthenticated();
    console.log("isAuthenticated")
    console.log(isAuthenticated)
    if (isAuthenticated) {
      console.log("Загрузка данных о пользователе")
      load_user();
    } else {
      console.log("Токен плохой")
      let refresh_t = localStorage.getItem('refresh')
      console.log(refresh_t)
      refreshAccess(refresh_t)
    }
  }, [isAuthenticated]);

  return (
    <div className="App">
      <Router>
        <PageTitle titles={titles} />
        <header>
          <NavbarD name="cew"></NavbarD>
        </header>

        <main style={{ marginBottom: 70 }}>
          <Routes>
            <Route path="/news" element={<News />}> </Route>
            <Route path="/news/:id" element={<News />}> </Route>

            <Route path="/decks" element={<Decks />}> </Route>
            <Route path="/decks/:id" element={<Deck />}> </Route>
            <Route path="/groupdecks/:group" element={<Decksgroup />}> </Route>
            <Route path="/decks_add/" element={<Deck_add />}> </Route>

            <Route path="/sets" element={<Sets />}> </Route>
            <Route path="/sets/:id" element={<Set />}> </Route>

            <Route path="/cards/:sid" element={<Cards />}> </Route>

            <Route path="/tournaments/" element={<TournamentsPage />}> </Route>
            <Route path="/tournaments/userreg" element={<TournamentsUserReg />}> </Route>
            <Route path="/tournaments/view/:id" element={<TournamentsView />}> </Route>
            <Route path="/tournaments/club/" element={<TournamentsClub />}> </Route>
            <Route path="/tournaments/club/:tid" element={<TournamentsDetails />}> </Route>
            <Route path="/tournaments/create" element={<TournamentsCreate />}> </Route>
            <Route path="/tournaments/run/:tid" element={<TournamentsRun />}> </Route>

            <Route path="/clubs/:id" element={<ClubView />}> </Route>

            <Route path="/statistics/clubs/" element={<ClubsStatistics />}> </Route>
            <Route path="/statistics/decks/" element={<DecksStatistics />}> </Route>
            <Route path="/statistics/cards/" element={<CardsMonthStatistics />}> </Route>

            <Route path="/statistics/sets/" element={<AllSetsStatistics />}> </Route>
            <Route path="/statistics/sets/:id" element={<CardsSetStatistics />}> </Route>

            <Route path="/statistics/month/" element={<AllMonthStatistics />}> </Route>
            <Route path="/statistics/month/:year/:month" element={<MonthStatistics />}> </Route>

            <Route path="/myprofile/" element={<MyProfile />}></Route>
            <Route path="/userdecks/" element={<UserDecksView />}></Route>
            <Route path="/profile/:id" element={<ProfileView />}></Route>

            <Route path="/login/" element={<Login />}> </Route>
            <Route path="/logintest/" element={<Logintest />}> </Route>
            <Route path="/reg/" element={<Signup />}> </Route>
            <Route path="/" element={<Home />}> </Route>
          </Routes>
        </main>
      </Router>
    </div>
  );
}

const PageTitle = ({ titles }) => {
  usePageTitle(titles);
  return null;
};

const mapStateToProps = function (state) {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
  };
};

export default connect(mapStateToProps, { load_user, checkAuthenticated, refreshAccess })(App);

const News = (props) => <div></div>;
