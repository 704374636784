import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { connect } from "react-redux";
import config_site from '../../config_site'

import {
    NavLink,
} from 'react-router-dom';
import { Col, Container, Row, Table } from 'react-bootstrap';

function ClubView({ is_club_owner, isAuthenticated }) {
    const [data, setData] = useState("[]");
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    const colors = {
        0: "#addebb", // Обычный турнир  //badbad
        1: "#fdc38c", // Турнир в рамках Лиги
        2: "#ff944d", // Лига+
        3: "#8cb9fd", // Турнир в рамках Регионала
        4: "#8ba3da", // Регионал+
        5: "#ffd1dc", // ГранПри
        6: "#ff7777", // ГранПри+?!
    }

    useEffect(() => {
        fetch(`${config_site.apiBaseUrl}/api/club/?id=` + params.id)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                console.log(data_in)
            });
        return () => {
            setData("[]");
        };
    }, []);

    return (data === "[]") ? (
        <div>Loading...</div>
    ) : (<Container>
        <div className="deck">
            <div className='deck-name'>
                {data.clubinfo.club_name}
                <span className='author'>{data.clubinfo.club_countre}</span>
            </div>
            <div className='deck-information'>
                Текст: {data.clubinfo.club_text}<br></br>
            </div>
       </div>
        <Row>
            <Col><h3>Предстоящие события</h3>
                <Row>
                    <Table striped bordered hover size="sm"><thead>
                        <tr>
                            <th style={{width:100}}>Дата</th>
                            <th>Название</th>
                        </tr>
                    </thead>
                        <tbody>
                            {data.coming.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ backgroundColor: colors[item.event_type] }}>{item.event_date.split('T')[0]
                                    }</td>
                                    <td align="left"><NavLink to={'/tournaments/view/' + item.id}>{item.event_name}</NavLink></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Col>
            <Col sm={1}></Col>
            <Col><h3>Завершившиеся события</h3>
                <Row>
                    <Table striped bordered hover size="sm"><thead>
                        <tr>
                            <th style={{width:100}}>Дата</th>
                            <th>Название</th>
                        </tr>
                    </thead>
                        <tbody>
                            {data.past.map((item, index) => (
                                <tr key={index}>
                                    <th style={{ backgroundColor: colors[item.event_type] }}>{item.event_date.split('T')[0]
                                    }</th>
                                    <td align="left"><NavLink to={'/tournaments/view/' + item.id} onClick={() => setIsLoading(true)}>{item.event_name}</NavLink></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Col>
        </Row>
        
    </Container>
    );
}



const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    is_club_owner: state.auth.is_club_owner
});

export default connect(mapStateToProps, null)(ClubView);

/**
 *      {is_club_owner ? <Button as={NavLink} to="/tournaments/club/" variant="success" size="lg">Начать/создать турнир</Button> : ''}*Отображать, когда владелец клуба
 * 
                Ссылка 1 (VK): {data.clubinfo.club_link_1}<br></br>
                Ссылка 2 (TG): {data.clubinfo.club_link_2}<br></br>
                Ссылка 3 (URL): {data.clubinfo.club_link_3}<br></br>
                Гугле МАПС???: <span className='author'>Карта, мать его</span>
 */