import React, { useState, useEffect } from 'react';
import UserProfileView from './UserProfileView';
import UserProfileEdit from './UserProfileEdit';
import config_site from '../../config_site';
import axios from 'axios';

const MyProfile = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchCurrentUserId = async () => {
            // Получите текущий userId из токена или другого источника
            const token = localStorage.getItem('access');
            const response = await axios.get(`${config_site.apiBaseUrl}/auth/users/me/`, {
                headers: { Authorization: `JWT ${token}` }
            });
            setUserId(response.data.id);
        };
        fetchCurrentUserId();
    }, []);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleSave = () => {
        setIsEditing(false);
    };

    if (!userId) return <div>Loading...</div>;

    return (
        <div>
            {isEditing ? (
                <UserProfileEdit userId={userId} onCancel={handleCancel} onSave={handleSave} />
            ) : (
                <UserProfileView userId={userId} onEdit={handleEdit} />
            )}
        </div>
    );
};

export default MyProfile;
