import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import config_site from '../../config_site';

const Meta = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        // Запрос к серверу
        axios.get(`${config_site.apiBaseUrl}/api/meta/`)
            .then(response => {
                const tableData = response.data.meta_data;
                
                // Получаем типы колод
                const deckTypes = tableData.meta.map(metaItem => metaItem['Стихия']);
                
                // Создаем колонки для таблицы
                const tableColumns = [
                    {
                        Header: 'Стихия',
                        accessor: 'deck_type1',
                    },
                    ...deckTypes.map(deckType => ({
                        Header: deckType,
                        accessor: deckType,
                    })),
                ];

                // Создаем данные для таблицы
                const combinedData = deckTypes.map((deckType1, index1) => {
                    const row = {
                        deck_type1: {
                            name: deckType1,
                            perc_metagame: tableData.meta[index1].perc_metagame,
                            count_game: tableData.meta[index1].count_game,
                        },
                    };
                    deckTypes.forEach((deckType2, index2) => {
                        row[deckType2] = tableData.winrates[index1][deckType2];
                    });
                    return row;
                });

                setData(combinedData);
                setColumns(tableColumns);
            })
            .catch(error => {
                console.error("There was an error fetching the data!", error);
            });
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    const renderCell = (cell) => {
        const value = cell.value;
        if (cell.column.id === 'deck_type1') {
            return (
                <td {...cell.getCellProps()} className="align-middle" style={{ width: '200px' }}>
                    <Link to={`/groupdecks/${value.name}`}>
                        {value.name}
                    </Link>
                    <div style={{ fontSize: '0.8em', color: '#6c757d' }}>
                        {value.perc_metagame}% | Матчей: {value.count_game}
                    </div>
                </td>
            );
        } else if (typeof value === 'string' || value instanceof String) {
            return <td {...cell.getCellProps()} className="text-center align-middle" style={{ width: '150px' }}>{value}</td>;
        } else if (typeof value === 'object') {
            return (
                <td {...cell.getCellProps()} className="text-center align-middle" style={{ width: '150px' }}>
                    <div style={{ fontSize: '1.0em', color: value.winrate >= 50.0 ? '#50C878' : '#ff0000' }}>{value.winrate === '-' ? '-' : <>{value.winrate}%</>}</div>
                    <div style={{ fontSize: '0.8em', color: '#6c757d' }}>{value.count_game}</div>
                </td>
            );
        }
    };

    return (
        <div className="container">
            <Table striped bordered hover responsive {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="text-center align-middle" style={{ width: column.id === 'deck_type1' ? '200px' : '150px' }}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => renderCell(cell))}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default Meta;
