import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import {
    NavLink,
} from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Tabs from "react-bootstrap/esm/Tabs";
import { Tab } from "bootstrap";
import Table from "react-bootstrap/esm/Table";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import TournamentsTab from "./TournamentsTab";

function Tournaments({ is_club_owner, isAuthenticated }) {

    return (
        <Row>
            <Col>
                <Tabs
                    defaultActiveKey="f1"
                    id="uncontrolled-tab-example"
                    className="mb-3">

                    <Tab eventKey="f1" title="Стандарт">
                        <TournamentsTab format='1' />
                    </Tab>

                    <Tab eventKey="f2" title="Драфт">
                        <TournamentsTab format='2'/>
                    </Tab>

                    <Tab eventKey="f3" title="Силед">
                        <TournamentsTab format='3'/>
                    </Tab>

                    <Tab eventKey="f4" title="Лиги">
                        <TournamentsTab format='11'/>
                    </Tab>

                    <Tab eventKey="f5" title="Регионалы">
                        <TournamentsTab format='12'/>
                    </Tab>
                    <Tab eventKey="f6" title="Гран-При">
                        <TournamentsTab format='13'/>
                    </Tab>



                </Tabs>
            </Col>
        </Row>
    );
}
//export default Tournaments;


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    is_club_owner: state.auth.is_club_owner
});

export default connect(mapStateToProps, null)(Tournaments);
// 8ba3da ffd1dc addebb