import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config_site from '../../config_site';
import './UserProfile.css';
import { Col, Row, Container, Card, Button, Table } from 'react-bootstrap';
import {
    NavLink,
} from 'react-router-dom';

const UserProfileView = ({ userId, onEdit }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('access');
        axios.get(`${config_site.apiBaseUrl}/api/myprofile/`, {
            headers: { Authorization: `JWT ${token}` }
        })
            .then(response => setUser(response.data))
            .catch(error => console.error('Error fetching user:', error));
    }, [userId]);

    if (!user) return <div>Loading...</div>;

    return (
        <Container>
            <Row>
                <Col>
                    <Card className="text-left mt-3">
                        <Card.Header>Данные профиля</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <p><b>Имя:</b> {user.user.first_name}</p>
                            </Card.Text>
                            <Card.Text>
                                <p><b>Фамилия:</b> {user.user.last_name}</p>
                            </Card.Text>
                            <Card.Text>
                                <p><b>Email:</b> {user.user.email}</p>
                            </Card.Text>
                            <Card.Text>
                                <p><b>Отображаемое имя:</b> {user.profile.display_name}</p>
                            </Card.Text>


                            <div><Button onClick={onEdit} disabled variant="primary">Редактировать профиль</Button></div>
                            <div className='mt-3'><Button as={NavLink} to="/userdecks/" variant="primary">Мои колоды</Button></div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card className="text-center mt-3">
                        <Card.Header>Статистика игр</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Отыграно турниров
                            </Card.Text>
                            <Card.Title>{user.user_rating.count_ivents}</Card.Title>
                            <Card.Text>
                                Отыграно матчей
                            </Card.Text>
                            <Card.Title>{user.user_rating.w + user.user_rating.l + user.user_rating.n}</Card.Title>
                            <Card.Text>
                                Побед - Поражений - Ничьих
                            </Card.Text>
                            <Card.Title>{user.user_rating.w} - {user.user_rating.l} - {user.user_rating.n}</Card.Title>
                            Процент побед: {Math.round(10000 * user.user_rating.w / (user.user_rating.w + user.user_rating.l + user.user_rating.n))/100} %
                            <Button as={NavLink} to="/tournaments/userreg/" variant="primary">Зарегистрироваться на турнир</Button>
                        </Card.Body>
                    </Card></Col>
                <Col sm={4}>
                    <Card className="text-center mt-3">
                        <Card.Header>Рейтинг игрока</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Общий
                            </Card.Text>
                            <Card.Title>{Math.round(user.user_rating.rating_elo * 100) / 100}</Card.Title>
                            <Card.Text>
                                Констрактед
                            </Card.Text>
                            <Card.Title>-</Card.Title>
                            <Card.Text>
                                Лимитед
                            </Card.Text>
                            <Card.Title>-</Card.Title>
                            <Button disabled variant="primary">История изменения</Button>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
            <Row className='mt-3'>
                <Col sm={8}>
                    Последние матчи
                    <Table>
                        <thead>
                            <tr>
                                <th>Оппонент</th>
                                <th>Результат</th>
                                <th>Колода</th>
                                <th>Колода оппонента</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user.rez_matches ?
                                user.rez_matches.map((item, index) => (
                                    <tr key={index}>
                                        <th>{item.name}</th>
                                        <th>{item.rezult}</th>
                                        <th>{item.deck1.id ? <NavLink to={`/decks/${item.deck1.id}`}>{item.deck1.name}</NavLink> : "-"}</th>
                                        <th>{item.deck2.id ? <NavLink to={`/decks/${item.deck2.id}`}>{item.deck2.name}</NavLink> : "-"}</th>

                                    </tr>
                                ))
                                : <></>}
                        </tbody>
                    </Table>
                </Col>
                <Col sm={4}>
                    Последние турниры
                    <Table>
                        <thead>
                            <tr>
                                <th>Турнир</th>
                                <th>Место</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user.rez_tourn ?
                                user.rez_tourn.map((item, index) => (
                                    <tr key={index}>
                                        <th>{item.id ? <NavLink to={`/tournaments/view/${item.id}`}>{item.event_name}</NavLink> : item.event_name}</th>
                                        <th>{item.rezult}</th>

                                    </tr>
                                ))
                                : <></>}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default UserProfileView;
