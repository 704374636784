import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Равнины', 'Горы', 'Леса', 'Болото', 'Тьма', 'Нейтральные'],
  datasets: [
    {
      label: 'Количество карт',
      data: [9, 0, 9, 0, 9, 3],
      backgroundColor: [
        'rgba(255, 206, 86, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(255, 99, 132, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(207, 73, 34, 0.5)',
      ],
      borderColor: [
        'rgba(255, 206,  86, 1)',
        'rgba( 54, 162, 235, 1)',
        'rgba( 75, 192, 192, 1)',
        'rgba(255,  99, 132, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(207,  73,  34, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export default function PureComponent(props) {
  return <Pie data={props.data}  
  width={200}
  height={200}
  options={{ maintainAspectRatio: false }
}/>;
}




