import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import config_site from '../../config_site';

function TournamentsRunTable(props) {
    const params = useParams();
    const [data, setData] = useState("[]");

    useEffect(() => {
        let config = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        }

        let url = `${config_site.apiBaseUrl}/api/RunRunTournament/?t_id=` + params.tid
        fetch(url, config).then(async response => {
            let data_in2 = await response.json();
            setData(data_in2);
            console.log("data_in2data_in2data_in2data_in2data_in2")
            console.log(data_in2)
            console.log("data_in2")

        });
        return () => {
            setData("[]")
        };
    }, [])

    const startRaund = async (item) => {
        let url = `${config_site.apiBaseUrl}/api/RunRunTournament/?cmd=s_r&t_id=` + params.tid

        let config = {
            method: 'POST',
            //body: JSON.stringify(selectedAnswers),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        }


        const res = await fetch(url, config);


        props.setisLoading(true)
    }

    return data == "[]" ? <>okjuiytyredftgyhujik</> : (
        //  className="mb-3"
        <Row className="mt-5">
            <h4>Распределение игроков по столам  {
                //на props.data.info
            }</h4>
            <Col sm={6}>
                <Table className="mt-3">
                    <thead>
                        <tr>
                            <th>Кто против кого</th>
                            <th>Номер стола</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.sorted_pairs.map((item, index) => (

                                <tr key={index}>
                                    <th>{item.pair}</th>
                                    <th>{item.table}</th>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Col>
            <Col sm={6}>
                <Table className="mt-3">
                    <thead>
                        <tr>
                            <th>№ стола</th>
                            <th>Участник 1</th>
                            <th></th>
                            <th>Участник 2</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.data.map((item, index) => (

                                <tr key={index}>
                                    <th>{item.table}</th>
                                    <th>{item.player1}</th>
                                    <th>против</th>
                                    <th>{item.player2}</th>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Col>
            <Button variant="success" size="lg" onClick={startRaund}>Начать раунд</Button>
        </Row>
    );
}

export default TournamentsRunTable;
