import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config_site from '../../config_site';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import {
    NavLink,
} from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Tabs from "react-bootstrap/esm/Tabs";
import { Tab } from "bootstrap";
import Table from "react-bootstrap/esm/Table";
import { Button, Pagination } from "react-bootstrap";
import { connect } from "react-redux";

function TournamentsTab(props) {
    const [current_page, setCurrent_page] = useState(1);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    const colors = {
        0: "#addebb", // Обычный турнир  //badbad
        1: "#fdc38c", // Турнир в рамках Лиги
        2: "#ff944d", // Лига+
        3: "#8cb9fd", // Турнир в рамках Регионала
        4: "#8ba3da", // Регионал+
        5: "#ffd1dc", // ГранПри
        6: "#ff7777", // ГранПри+?!
    }

    const page = (param) => {
        console.log(param)
    }


    useEffect(() => {

        let url = `${config_site.apiBaseUrl}/api/events/?`

        switch (props.format) {
            case "1": url += "t_format=1"; break;
            case "2": url += "t_format=2"; break;
            case "3": url += "t_format=3"; break;
            case "11": url += "t_type=1"; break;
            case "12": url += "t_type=3"; break;
            case "13": url += "t_type=5"; break;
        }
        url += "&p=" + current_page;

        fetch(url)
            .then(async response => {
                let data_in = await response.json();
                setData(data_in);
                //console.log('data_in')
                //console.log(data_in)
                //console.log('data_in')
                setIsLoading(false);
            });
        return () => {
            setData([]);
            setIsLoading(true);
        };
    }, [current_page]);

    return isLoading ? (
        <div>Loading...</div>
    ) : (<>
        <Pagination>
            <Pagination.First onClick={() => setCurrent_page(1)} />
            <Pagination.Prev onClick={() => setCurrent_page(data.p_pages)} />
            {
                [...Array(data.count_pages).keys()].map((index) => (
                    <Pagination.Item key={index} active={(index + 1) === current_page} onClick={() => setCurrent_page(index + 1)}>{(index + 1)}</Pagination.Item>
                ))
            }
            <Pagination.Next />
            <Pagination.Last />
        </Pagination>
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th style={{ width: 100 }}>Дата</th>
                    <th>Название турнира</th>
                    <th>Количество игроков</th>
                    <th>Количество учтенных колод</th>
                    <th>Полнота</th>
                    <th>Клуб</th>
                </tr>
            </thead>
            <tbody>

                {data.data.map((item, index) => (
                    <tr key={index}>
                        <th style={{ backgroundColor: colors[item.event_type] }}>{item.event_date.split('T')[0]}</th>
                        <td align="left"><NavLink to={'/tournaments/view/' + item.id}>{item.event_name}</NavLink>

                            {item.ts ? <>: {item.ts.map((item1, index) => (
                                <div key={index}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{index + 1}. <NavLink to={"/tournaments/view/" + item1.id}>{item1.event_name}</NavLink></div>

                            ))}</> : <></>}</td>
                        <th>{item.event_count_player}</th>
                        <th>{item.deck_count}</th>
                        <th>{Math.round(item.deck_count / (item.event_count_player * item.event_count_deck + 0.0) * 100, 2)}%</th>
                        <th><NavLink to={'/clubs/' + item.club_data.id}>{item.club_data.club_name}</NavLink></th>
                    </tr>
                ))}

            </tbody>
        </Table>

        <Pagination>
            <Pagination.First onClick={() => setCurrent_page(1)} />
            <Pagination.Prev onClick={() => setCurrent_page(data.p_pages)} />
            {
                [...Array(data.count_pages).keys()].map((index) => (
                    <Pagination.Item key={index} active={(index + 1) === current_page} onClick={() => setCurrent_page(index + 1)}>{(index + 1)}</Pagination.Item>
                ))
            }
            <Pagination.Next />
            <Pagination.Last />
        </Pagination>
    </>
    );
}
//export default Tournaments;


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    is_club_owner: state.auth.is_club_owner
});

export default TournamentsTab;
// 8ba3da ffd1dc addebb