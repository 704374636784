import Container from "react-bootstrap/esm/Container";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import config_site from '../../config_site';

function TournamentsRunUserOut(props) {
    const params = useParams();
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        let config = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        }

        let url = `${config_site.apiBaseUrl}/api/UsersInPlay/?t_id=` + params.tid
        fetch(url, config).then(async response => {
            let data_in2 = await response.json();
            setData(data_in2);
            console.log("UsersInPlay")
            console.log(data_in2)
            console.log("UsersInPlay")

            // Инициализируем selectedAnswers с данными с сервера
            const initialSelectedAnswers = data_in2.map(player => [
                player.id, 
                !player.is_in_play, 
                player.is_next_raund_aw
            ]);
            setSelectedAnswers(initialSelectedAnswers);
        });
        return () => { };
    }, [params.tid])

    const userOut = async () => {
        let url = `${config_site.apiBaseUrl}/api/RunRunTournament/?cmd=u_o&t_id=` + params.tid

        let config = {
            method: 'POST',
            body: JSON.stringify(selectedAnswers),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        }

        const res = await fetch(url, config);

        props.setIsUserOut(true)
    }

    const handleOptionChange = (player1_id, isUserOut) => {
        setSelectedAnswers(prevSelectedAnswers => {
            const updatedAnswers = prevSelectedAnswers.map(answer => 
                answer[0] === player1_id ? [player1_id, isUserOut, false] : answer
            );
            return updatedAnswers;
        });
    }

    const handleOptionChange2 = (player1_id, isAW) => {
        setSelectedAnswers(prevSelectedAnswers => {
            const updatedAnswers = prevSelectedAnswers.map(answer => 
                answer[0] === player1_id ? [player1_id, false, isAW] : answer
            );
            return updatedAnswers;
        });
    };

    const handleOptionChange3 = (player1_id) => {
        setSelectedAnswers(prevSelectedAnswers => {
            const updatedAnswers = prevSelectedAnswers.map(answer => 
                answer[0] === player1_id ? [player1_id, false, false] : answer
            );
            return updatedAnswers;
        });
    }

    return (
        <Row>
            <Row><Col>Если участники убыли или имеют автопроход в следующий раунд, отметьте это бубликом</Col></Row>
            <Row>
                <Col></Col>
                <Col sm={7}>
                    {data.map((item, index) => {
                        const selectedAnswer = selectedAnswers.find(answer => answer[0] === item.id);
                        return (
                            <Row key={index}>
                                <Col>{item.first_name}</Col>
                                <Col>
                                    <Form.Check
                                        inline
                                        label="Выбыл"
                                        name={"group_" + index}
                                        type="radio"
                                        id={`inline-checkbox-1` + index}
                                        checked={selectedAnswer ? selectedAnswer[1] : false}
                                        onChange={(e) => handleOptionChange(item.id, e.target.checked)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        inline
                                        label="Автопобеда"
                                        name={"group_" + index}
                                        type="radio"
                                        id={`inline-checkbox-2` + index}
                                        checked={selectedAnswer ? selectedAnswer[2] : false}
                                        onChange={(e) => handleOptionChange2(item.id, e.target.checked)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        inline
                                        label="Участвует"
                                        name={"group_" + index}
                                        type="radio"
                                        id={`inline-checkbox-3` + index}
                                        checked={selectedAnswer ? (!selectedAnswer[1] && !selectedAnswer[2]) : false}
                                        onChange={(e) => handleOptionChange3(item.id)}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                    <Button
                        variant="success"
                        size="lg"
                        onClick={userOut}
                    >
                        Подтвердить
                    </Button>
                </Col>
                <Col></Col>
            </Row>
        </Row>
    );
}

export default TournamentsRunUserOut;
