import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import DeleteDeck from "./DeleteDeck";
import AddDeck from "./AddDeck";
import RedDeck from "./RedDeck";
import config_site from '../../config_site';

function TournamentsRunRezultAndAddDecks(props) {
    const params = useParams();
    const [data, setData] = useState("[]");
    const [f, setF] = useState(false);

    let event_raspred = {
        0: "Швейцарская система распределения",
        1: "Олимпийская система распределения",
    }
    let event_format = {
        1: "Стандарт",
        2: "Драфт",
        3: "Силед",
    }
    let event_koef = {
        0: "Ранжирование как в МТГ",
        1: "Ранжирование как на мили",
        2: "Ранжирование по коэффициенту Бухгольца",
    }

    useEffect(() => {
        let config = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            },
        }

        let url = `${config_site.apiBaseUrl}/api/RezultTournametView/?t_id=` + params.tid + "&aer=we"
        fetch(url, config).then(async response => {
            let data_in2 = await response.json();
            setData(data_in2);
            setF(true)
            console.log(data_in2)
        });
        return () => {
        };
    }, [f])




    return (data === "[]") ? (
        <div>Загрузка таблицы результатов</div>
    ) : (
        <Row className="mt-3">
            <Col sm={12}><h3>{data.event_name} - {event_format[data.event_format]}</h3></Col>
            <Col sm={12}>({event_raspred[data.event_raspred]} - {event_koef[data.event_koef]})</Col>
            <Col sm={12}>
                <Table className="mt-3">
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Участник</th>
                            <th>Результат<br></br>П (АП)-Н-Пр</th>
                            {(data.event_koef <= 1) ?
                                <><th>% выйгранных<br></br>матчей оппонентов</th>
                                    <th>% побед</th>
                                    <th>% выйгранных<br></br>игр оппонентов</th>
                                </> : (data.event_koef <= 2) ?
                                    <><th>Коэффициент<br></br>Бухгольца</th>
                                    </> : ""}
                            <th>Колода</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.data.map((item, index) => (
                            <tr key={index}>
                                {(data.raspred === 1) ?
                                    <><th>Топ-{Math.pow(2, Math.ceil(Math.log2(index + 1)))}</th>
                                    </> : <th>{index + 1}</th>}

                                <th>{item.user > 0 ? <NavLink to={`/profile/${item.user}`}>{item.is_in_play? item.first_name:<s>{item.first_name}</s>}</NavLink> : item.is_in_play? item.first_name:<s>{item.first_name}</s>}</th>
                                <th>{item.battle_result_w + item.battle_aw_ball} ({item.battle_aw_ball})-{item.battle_result_n}-{item.battle_result_l}</th>
                                {(data.event_koef <= 1) ?
                                    <>
                                        <th>{item.p1.rez}</th>
                                        <th>{item.p2.rez}</th>
                                        <th>{item.p3.rez}</th>
                                    </> : (data.event_koef <= 2) ?
                                        <>
                                            <th>{item.p1.rez}
                                                
                                            </th>
                                        </> : ''}
                                <th>
                                    {[...Array(data.event_count_deck).keys()].map((index) => (
                                        item.deckAdded_id[index] ?
                                            <RedDeck key={index} f={setF}
                                                deckdata={item.deckAdded_id[index]} /> :
                                            <AddDeck key={index} f={props.setisLoading}
                                                decks={item.deckAdded_id} number={index} item={item}
                                                event_format={data.event_format} />
                                    ))}
                                </th>
                            </tr>

                        ))}

                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}

export default TournamentsRunRezultAndAddDecks;
