import { useParams, NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config_site from '../../config_site';
import '../Deck/deck.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Table from "react-bootstrap/esm/Table";
import { Alert, Button } from "react-bootstrap";
import { connect } from "react-redux";
import EditorText from "../Editor/EditorText";
import { EditorState, convertFromRaw } from "draft-js";
import decorator from "../Editor/decorators";
import StandingsView from "./StandingsView";

function TournamentsView(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tour, setTour] = useState(0);
    const params = useParams();
    const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));

    let month = {
        1: 'января',
        2: 'февраля',
        3: 'марта',
        4: 'апреля',
        5: 'мая',
        6: 'июня',
        7: 'июля',
        8: 'августа',
        9: 'сентября',
        10: 'октября',
        11: 'ноября',
        12: 'января',
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(`${config_site.apiBaseUrl}/api/EventsDisplayInfoView/?t_id=${params.id}`);
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error);
                }
                let data_in = await response.json();
                setData(data_in);

                if (data_in.event_text) {
                    let contentconvertFromRaw = convertFromRaw(data_in.event_text);
                    const newEditorState = EditorState.createWithContent(contentconvertFromRaw, decorator);
                    setEditorState(newEditorState);
                }
                setTour(data_in.event_count_tour)
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                //setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            setData([]);
            setIsLoading(true);
        };
    }, [params.id]);

    return isLoading ? (
        <div>Loading...</div>
    ) : error ? (
        <Alert variant="danger">{error}</Alert>
    ) : (
        <Container>
            <Breadcrumb className='mt-3'>
                <Breadcrumb.Item><NavLink to={'/'}>Главная</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item><NavLink to={'/tournaments/'}>Турниры</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item active>{data.event_name}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="deck">
                <div className='deck-name'>
                    <h3>{data.event_name}</h3>
                </div>
                <div className='deck-information'>
                    Формат: {data.event_format === 1 ? "Стандарт" : data.event_format === 2 ? "Драфт" : data.event_format === 3 ? "Силед" : "---"}<br />
                    Описание: <br />
                    {data.event_text && <EditorText placeholder='' editorState={editorState} setEditorState={setEditorState} readOnly={true} />}
                    <br />
                    Дата проведения: {Number(data.event_date.split('T')[0].split('-')[2])} {month[Number(data.event_date.split('T')[0].split('-')[1])]} {data.event_date.split('T')[0].split('-')[0]} в {data.event_date.split('T')[1].split(':')[0]}:{data.event_date.split('T')[1].split(':')[1]}<br />
                    {data.event_previous_tournament && <><br />Предыдущий турнир: <NavLink to={`/tournaments/view/${data.event_previous_tournament.id}`}>{data.event_previous_tournament.event_name}</NavLink></>}
                    {data.event_next_tournament && <><br />Следующий турнир: <NavLink to={`/tournaments/view/${data.event_next_tournament.id}`}>{data.event_next_tournament.event_name}</NavLink></>}
                    {data.event_in_event_1 || data.event_in_event_2 ? <><br />Является частью: </> : null}
                    {data.event_in_event_1 && <NavLink to={`/tournaments/view/${data.event_in_event_1.id}`}>{data.event_in_event_1.event_name}</NavLink>}
                    {data.event_in_event_2 && <NavLink to={`/tournaments/view/${data.event_in_event_2.id}`}>{data.event_in_event_2.event_name}</NavLink>}
                    <br />
                </div>
            </div>
            
            <StandingsView  tour_pl={data.event_tour} tour={data.event_count_tour}/>

        </Container>
    );
}

export default TournamentsView;
